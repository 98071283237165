import styled, { css } from "styled-components";
import promoBg from "../../img/pattern/scout-bg.png";
import { NewsLink } from "../NewsThumb/styles";
import { IScoutItem } from "./types";

export const ScoutPattern = css`
  overflow: auto;
  background-image: url(${promoBg}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.blueLight},
      ${({ theme }) => theme.colors.fantasy}
    );
  background-repeat: no-repeat;
  background-position: right -75px bottom -230px, 0;
`;

export const ScoutBody = styled.figcaption`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    position: relative;
    width: auto;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 90%;
      height: 0.4rem;
      margin: auto;
      content: "";
      background: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.blueLight},
        ${({ theme }) => theme.colors.fantasy}
      );
      transition: 0.4s;
    }
  }
`;

export const ScoutTitle = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.4;

  ${NewsLink}:hover & {
    text-decoration: underline;
  }
`;

export const ScoutList = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.4rem;
    margin-left: -0.4rem;
  }
`;

export const ScoutItem = styled.li<IScoutItem>`
  margin-bottom: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 ${(props) => `${props.$width}%`};
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
`;
