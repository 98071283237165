import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ControlArrowRight } from "../Icons/Arrows";
import { LinkStyles } from "../Link/Link";

interface IBoldLinkWrapProps {
  align?: "left" | "center" | "right";
}

export const BoldLinkWrap = styled.div<IBoldLinkWrapProps>`
  margin: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  text-align: ${(props) => (props.align ? props.align : "left")};
`;

export const BoldLinkStyles = css`
  ${LinkStyles}
  display: inline-flex;
  gap: ${(props) => props.theme.space[2]};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const StyledBoldLink = styled(Link)`
  ${BoldLinkStyles}
`;

export interface IProps {
  children: React.ReactNode;
  to: string;
}

const BoldLink: React.FC<IProps> = ({ children, to }) => (
  <StyledBoldLink to={to}>
    {children}
    <ControlArrowRight color="primary" />
  </StyledBoldLink>
);

export default BoldLink;
