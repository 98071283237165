import { rgba } from "polished";
import styled from "styled-components";

export const GradBorderBox = styled.div`
  position: relative;
  margin-top: ${(props) => props.theme.space[2]};

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    content: "";
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${(props) => props.theme.colors.lightGrey} 15%,
      ${(props) => props.theme.colors.lightGrey} 49%,
      ${(props) => props.theme.colors.lightGrey} 85%,
      ${rgba("white", 0)} 100%
    );
  }
`;

export const Divider = styled.hr`
  display: block;
  height: 1px;
  padding: 0;
  margin: ${(props) => props.theme.space[6]} 0;
  border: 0;
  border-top: ${(props) => props.theme.borders[2]};
`;

export const EventEntryCopy = styled.p`
  padding: ${(props) => `${props.theme.space[2]} ${props.theme.space[5]}`};
  margin-top: ${(props) => props.theme.space[4]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
  background-color: ${rgba("white", 0.4)};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    margin-top: ${(props) => props.theme.space[8]};
  }
`;

export const DividerLight = styled.hr`
  display: block;
  height: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  border-top: 1px solid ${({ theme }) => rgba(theme.colors.purple70, 0.1)};
`;
