import styled from "styled-components";
import ChevronDoubleLeftIcon from "../../img/icons/chevron-double-left.svg?react";
import ChevronDoubleRightIcon from "../../img/icons/chevron-double-right.svg?react";
import ChevronLeftIcon from "../../img/icons/chevron-left.svg?react";
import ChevronRightIcon from "../../img/icons/chevron-right.svg?react";
import BaseNextIcon from "../../img/icons/next.svg?react";
import BasePrevIcon from "../../img/icons/previous.svg?react";

export const ChevronLeft = styled(ChevronLeftIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ChevronRight = styled(ChevronRightIcon)`
  color: currentcolor;
`;

export const ChevronDoubleLeft = styled(ChevronDoubleLeftIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ChevronDoubleRight = styled(ChevronDoubleRightIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

export const PrevIcon = styled(BasePrevIcon)`
  margin-right: 0.6rem;
  vertical-align: middle;
  fill: currentcolor;
`;

export const NextIcon = styled(BaseNextIcon)`
  margin-left: 0.6rem;
  vertical-align: middle;
  fill: currentcolor;
`;
