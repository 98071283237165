import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useClipboard from "react-use-clipboard";
import styled from "styled-components";
import Button from "../components/Button";
import Copy from "../components/Copy";
import { FormBackground } from "../components/FormBackground";
import HelmetHead from "../components/HelmetHead";
import { ControlArrowRight } from "../components/Icons";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import Title from "../components/Title";
import { RootState } from "../rtk-core/src/app/store";
import { useLazyFetchEntrySummaryQuery } from "../rtk-core/src/features/api/entries";
import { useLazyFetchLeagueCodeQuery } from "../rtk-core/src/features/api/leagues";
import { getLeagueForEntry } from "../rtk-core/src/features/entries";
import { getCode } from "../rtk-core/src/features/leagues";
import { getPlayerData } from "../rtk-core/src/features/player";

const LeagueInvite = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[4]};
  font-size: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.fantasy};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[1]};
`;

const LeagueInviteCode = styled.strong`
  margin-left: ${({ theme }) => theme.space[1]};
  color: white;
`;

const Hr = styled.hr`
  height: 1px;
  margin-top: 23px;
  margin-bottom: 23px;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  border: 0;
`;

const inviteMessage = (code: string, leagueName: string) => [
  "Hello,",
  `For the 2024/25 Fantasy Premier League why don't you join my mini-league "${leagueName}"?`,
  `Joining the league couldn't be easier. Simply use the link below and you'll be added automatically after you've entered the game.`,
  `${window.location.origin}/leagues/auto-join/${code}`,
  `League Code: ${code}`,
  "Looking forward to playing against you this season!",
];

const Invite: React.FC = () => {
  const { leagueId } = useParams<{ leagueId: string }>();
  const leagueNumber = leagueId ? parseInt(leagueId, 10) : 0;

  const player = useSelector((state: RootState) => getPlayerData(state));
  const entryId = player ? player.entry : 0;
  const code = useSelector((state: RootState) =>
    leagueNumber ? getCode(state, leagueNumber) : null
  );
  const league = useSelector((state: RootState) =>
    leagueNumber && entryId
      ? getLeagueForEntry(state, entryId, leagueNumber)
      : null
  );

  const codeToCopy = code ? code : "";
  const leagueName = league ? league.name : "";
  const message = inviteMessage(codeToCopy, leagueName);
  const emailText = message ? message.join("\n\n") : "";
  const emailBody = encodeURIComponent(emailText);
  const joinUrl = `${window.location.origin}/leagues/auto-join/${code}`;
  const [isCopied, setCopied] = useClipboard(codeToCopy, {
    successDuration: 500,
  });
  const [isCopiedUrl, setCopiedUrl] = useClipboard(joinUrl, {
    successDuration: 500,
  });
  const [isCopiedEmail, setCopiedEmail] = useClipboard(emailText, {
    successDuration: 500,
  });

  const [fetchLeagueCodeTrigger] = useLazyFetchLeagueCodeQuery();
  const [fetchEntrySummaryTrigger] = useLazyFetchEntrySummaryQuery();

  useEffect(() => {
    if (leagueNumber) {
      fetchLeagueCodeTrigger(leagueNumber);
    }
    if (entryId) {
      fetchEntrySummaryTrigger({ entryId: entryId });
    }
  }, [leagueNumber, entryId, fetchLeagueCodeTrigger, fetchEntrySummaryTrigger]);

  if (!codeToCopy || !leagueNumber || !league) {
    return null;
  }

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <HelmetHead
          title={"Create & Join Fantasy Football Leagues | PL Challenges"}
          description={
            "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
          }
        />
        <GridItem $colSpan={1}>
          <FormBackground>
            <Copy>
              <Title>Invite players to join {leagueName}</Title>
              <LeagueInvite onDoubleClick={setCopied}>
                Code to join this league:{" "}
                <LeagueInviteCode>
                  {isCopied ? "Copied!" : codeToCopy}
                </LeagueInviteCode>
              </LeagueInvite>

              <Button onClick={setCopiedUrl}>
                {isCopiedUrl ? "Copied!" : "Copy auto-join link"}
              </Button>

              <Hr />

              <p>
                When you click the link below, the link will attempt to open
                your default email program and start a new email. If you do not
                have a default email program, like Outlook or Mail, enabled on
                your computer, clicking the link may not result in any action or
                you may be prompted to set up an email program.
              </p>

              <Button
                onClick={() =>
                  (window.location.href = `mailto:?to=&subject=Play%20Fantasy%20Premier%20League&body=${emailBody}`)
                }
              >
                Share league code by email <ControlArrowRight />
              </Button>

              <Hr />

              <p>
                If you use a browser based webmail application, like Gmail or
                Yahoo!, you can copy and paste the text below:
              </p>

              <div className="bg-white p-4 mb-3" onDoubleClick={setCopiedEmail}>
                {message.map((line) => (
                  <p key={line}>{line}</p>
                ))}
              </div>

              <Button onClick={setCopiedEmail}>
                {isCopiedEmail ? "Copied!" : "Copy email text"}
              </Button>
            </Copy>
          </FormBackground>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export { Invite as InviteTest };

export default Invite;
