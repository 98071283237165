import { ArrowRight } from "../../Icons";
import { rgba, size } from "polished";
import styled from "styled-components";

export const RankArrow = styled(ArrowRight)`
  ${size(16)};
  fill: ${(props) => rgba(props.theme.colors.primary, 0.4)};
`;

export const RankInfoBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RankLevelsRanksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  min-width: 720px;
  padding: ${(props) => `0 ${props.theme.space[2]}`};
  margin: ${(props) => `${props.theme.space[4]} auto`};
`;

export const RankInfoWrapper = styled.div`
  /* 
  padding-bottom and hidden overflow-y added to prevent
  system-settings scroll bar from obscuring content.
  */
  padding-bottom: ${(props) => props.theme.space[2]};
  overflow: scroll;
  overflow-y: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    padding-bottom: ${(props) => props.theme.space[1]};
  }
`;
