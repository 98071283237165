import styled from "styled-components";

export const ButtonWrap = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.space[6]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 20%;
  }
`;
