import styled, { css } from "styled-components";
import { IOpponentDifficulty } from "./types";

export const StyledOpponent = styled.div<IOpponentDifficulty>`
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 1rem;
  text-align: center;
  border-radius: 0.2rem;

  ${({ $difficulty, theme }) =>
    $difficulty &&
    css`
      color: ${theme.colors.difficulties[$difficulty].color};
      background-color: ${theme.colors.difficulties[$difficulty].bg};
    `};
`;
