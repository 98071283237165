import styled, { css } from "styled-components";
import { ButtonHyperlink } from "../ButtonLink";

export const LoginBox = styled.div`
  padding: ${(props) => props.theme.space[3]};
  background-color: ${(props) => props.theme.colors.purple5};
  border: ${(props) => props.theme.borders[0]};
  border-radius: ${(props) => props.theme.radii[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[6]};
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
    gap: ${(props) => props.theme.space[6]};
    align-items: flex-end;
  }
`;

export const LoginTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0.9rem 0;
  }
`;

export const LoginItem = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 1;
  }
`;

export const LoginControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;
    gap: ${(props) => props.theme.space[6]};
  }
`;

const LoginLinkStyles = css`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: auto;
    min-width: 18rem;
  }
`;

export const LoginHyperlink = styled(ButtonHyperlink)`
  ${LoginLinkStyles}
`;

export const SocialLogin = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-direction: row;

    > a {
      flex: 1;
    }
  }
`;
