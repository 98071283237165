import { hiDPI } from "polished";
import styled from "styled-components";
import pattern1_2x from "../../img/pattern/pattern-1-1236.png";
import pattern1 from "../../img/pattern/pattern-1-874.png";

export const StyledClosedEvent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  padding: ${(props) => props.theme.space[3]};
  text-align: center;
  background-image: url(${pattern1}), url(${pattern1}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.fantasy},
      ${(props) => props.theme.colors.blueLight}
    );
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right -4px bottom 204px, left -100px bottom -50px, 0 0;
  background-size: 188px 167px, 188px 167px, auto;
  border-radius: ${(props) => props.theme.radii[4]};

  ${hiDPI(2)} {
    background-image: url(${pattern1_2x}), url(${pattern1_2x}),
      linear-gradient(
        to right,
        ${(props) => props.theme.colors.fantasy},
        ${(props) => props.theme.colors.blueLight}
      );
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    min-height: 270px;
    background-position: right -370px top -240px, left -365px bottom -225px, 0 0;
    background-size: 666px 947px, 666px 947px, auto;
  }
`;

export const ClosedEventHeading = styled.h3`
  margin-bottom: ${(props) => props.theme.space[3]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
  line-height: 1.2;
`;

export const ClosedEventCopy = styled.p`
  font-size: ${(props) => props.theme.fontSizes[3]};
`;
