import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledSubNav = styled.div<ISubNavStyles>`
  display: flex;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: ${({ $alignCenter = false }) => ($alignCenter ? "auto 33%" : 0)};
  }
`;

const NavList = styled.ul<ISubNavStyles>`
  display: flex;
  flex: ${({ $isEqual = false }) => ($isEqual ? 1 : "none")};
  padding: 0;
  list-style-type: none;
  background: ${(props) => props.theme.colors.purple5};
  border: ${(props) => props.theme.borders[0]};
  border-radius: 0.2rem;
`;

const StyledListItem = styled.li<ISubNavStyles>`
  flex: ${({ $isEqual = false }) => ($isEqual ? 1 : "none")};
`;

const StyledNavLink = styled(NavLink)<ILinkStyles>`
  display: block;
  padding: 0.6rem ${(props) => props.theme.space[4]};
  margin: 0.1rem;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  color: inherit;
  text-align: center;
  text-decoration: none;
  border-radius: 0.2rem;

  &.active {
    background: white;
    border-radius: 2px;
  }
`;

interface ISubNavStyles {
  $alignCenter?: boolean;
  $isEqual?: boolean;
}

interface ILinkStyles {
  $isCurrent?: boolean;
}

interface ILinks {
  href: string;
  text: string;
}

interface ISubNav {
  links: ILinks[];
  $alignCenter?: boolean;
  $isEqual?: boolean;
}

const SubNav: React.FC<ISubNav> = ({
  links,
  $isEqual = false,
  $alignCenter = false,
}) => (
  <StyledSubNav $alignCenter={$alignCenter}>
    <NavList $isEqual={$isEqual}>
      {links.map((l) => (
        <StyledListItem $isEqual={$isEqual} key={l.href}>
          <StyledNavLink to={l.href} end>
            {l.text}
          </StyledNavLink>
        </StyledListItem>
      ))}
    </NavList>
  </StyledSubNav>
);

export default SubNav;
