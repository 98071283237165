import { formatInTimeZone } from "date-fns-tz";
import { size } from "polished";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BaseExternalIcon from "../../img/icons/external.svg?react";
import tvIcon from "../../img/tv.png";
import tvIcon2x from "../../img/tv@2x.png";
import { getTeamsById } from "../../rtk-core/src/features/teams";
import { formatRawAsISO } from "../../utils/datetime";
import Badge from "../Badge";
import Dialog from "../Dialog";
import DialogBody from "../DialogBody";
import DialogHeader from "../DialogHeader";
import DialogManager from "../DialogManager";
import { FixtureBroadcastersContext } from "../FixtureBroadcasters";
import SubHeading from "../SubHeading";
import Table from "../Table";
import { NewTabCopy } from "../Utils/Utils";
import {
  FixtureBadge,
  FixtureKOTime,
  StyledFixture,
  Team,
  TeamH,
  TeamName,
  Teams,
  TimePlayed,
} from "./styles";
import {
  IFixtureScoreProps,
  ILogoProps,
  IMultipleBroadcastersDialog,
  Props,
} from "./types";

const FixtureScore = styled.div<IFixtureScoreProps>`
  display: flex;
  flex: 0 0 4.8rem;
  gap: ${({ theme }) => theme.space[1]};
  justify-content: space-between;
  padding: 0.2rem ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  color: #fff;
  background: ${({ theme, finished }) =>
    finished ? `${theme.colors.primary}` : theme.colors.pinkToOrange};
  border-radius: ${({ theme }) => theme.radii[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

const Broadcaster = styled.div`
  margin: 0.7rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[6]}) {
    position: absolute;
    top: 50%;
    right: 0;
    width: 24%;
    margin: 0;
    text-align: right;
    transform: translateY(-50%);
  }
`;

const BroadcasterBox = styled.div`
  padding: 0 ${({ theme }) => theme.space[1]};
  text-align: center;
`;

const BroadcasterList = styled.ul`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[6]}) {
    display: block;
  }
`;

const BroadcasterItem = styled.li`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[1]};
  padding-left: ${({ theme }) => theme.space[1]};

  :not(:last-child) {
    text-align: right;
    border-right: 1px solid ${({ theme }) => theme.colors.grey};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[6]}) {
    display: inline-block;

    :not(:last-child) {
      text-align: left;
    }
  }
`;

const StyledLogo = styled.img`
  display: inline;
  vertical-align: middle;
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const TVButton = styled.button`
  padding: ${({ theme }) => theme.space[1]};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: inherit;
  color: black;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: 0;
  border-radius: 2px;
`;

const TVLogo = styled.img`
  margin-right: ${({ theme }) => theme.space[1]};
  vertical-align: middle;
`;

const ButtonText = styled.span`
  vertical-align: middle;
`;

const TVLink = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.space[1]} 0.4rem;
  margin: ${({ theme }) => theme.space[1]} 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border: 0;
  border-radius: 2px;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
    font-size: inherit;
  }
`;

const ExternalIcon = styled(BaseExternalIcon)`
  ${size(10)}
  margin-left: ${({ theme }) => theme.space[1]};
  vertical-align: middle;
  fill: black;
`;

const MultipleBroadcastersDialog: React.FC<IMultipleBroadcastersDialog> = ({
  broadcasters,
  handleHide,
}) => (
  <Dialog closeDialog={handleHide}>
    <DialogHeader closeDialog={handleHide}>TV Info</DialogHeader>
    <DialogBody isPadded={true}>
      <SubHeading>Broadcasters</SubHeading>
      <div className="-mx-2">
        <Table>
          <thead>
            <tr>
              <th scope="col">Broadcaster</th>
              <th scope="col">{"&nbsp"};</th>
              <th scope="col">More info</th>
            </tr>
          </thead>
          <tbody>
            {broadcasters.map((broadcaster) => (
              <tr>
                <td>
                  {broadcaster.name.substring(
                    broadcaster.name.indexOf(" - ") + 3
                  )}
                </td>
                <td>
                  <Logo
                    abbreviation={broadcaster.abbreviation}
                    name={broadcaster.name}
                  />
                </td>
                <td>
                  <TVLink
                    href={broadcaster.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonText>Visit site</ButtonText>
                    <NewTabCopy />
                    <ExternalIcon />
                  </TVLink>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </DialogBody>
  </Dialog>
);

const Logo: React.FC<ILogoProps> = ({ abbreviation, name }) => {
  const path =
    "https://resources.premierleague.com/premierleague/broadcasters/global";
  const abbr = abbreviation.toLowerCase();
  return (
    <StyledLogo
      srcSet={`${path}/${abbr}.png, ${path}/${abbr}@x2.png 2x`}
      src={`${path}/${abbr}.png`}
      alt={name}
    />
  );
};

const Fixture: React.FC<Props> = ({ fixture }) => {
  const teamsById = useSelector(getTeamsById);
  const fb = React.useContext(FixtureBroadcastersContext);
  const broadcasterInfo =
    fixture.event && fb[fixture.event] && fb[fixture.event][fixture.id]
      ? fb[fixture.event][fixture.id]
      : null;
  return (
    <StyledFixture
      $isStarted={fixture.started && fixture.team_h_score !== null}
    >
      <Teams>
        {fixture.started && !fixture.finished && (
          <TimePlayed>{fixture.minutes}'</TimePlayed>
        )}
        <TeamH>
          <TeamName title={teamsById[fixture.team_h].name}>
            <span>{teamsById[fixture.team_h].short_name}</span>
          </TeamName>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_h]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 32px"
            />
          </FixtureBadge>
        </TeamH>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore finished={fixture.finished_provisional}>
            <span>{fixture.team_h_score}</span>
            <span>-</span>
            <span>{fixture.team_a_score}</span>
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm"
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <Team>
          <FixtureBadge>
            <Badge
              team={teamsById[fixture.team_a]}
              isPresentation={true}
              sizes="(min-width: 1024px) 40px, 32px"
            />
          </FixtureBadge>
          <TeamName title={teamsById[fixture.team_a].name}>
            <span>{teamsById[fixture.team_a].short_name}</span>
          </TeamName>
        </Team>
      </Teams>
      {broadcasterInfo && broadcasterInfo.length > 0 && (
        <Broadcaster>
          {broadcasterInfo.length > 2 ? (
            <DialogManager
              render={(showDialog, handleShow, handleHide) => (
                <>
                  <ButtonWrap>
                    <TVButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShow();
                      }}
                    >
                      <TVLogo
                        src={tvIcon}
                        srcSet={`${tvIcon}, ${tvIcon2x} 2x`}
                        alt=""
                      />
                      <ButtonText>Multiple Broadcasters</ButtonText>
                    </TVButton>
                  </ButtonWrap>
                  {showDialog && (
                    <MultipleBroadcastersDialog
                      broadcasters={broadcasterInfo}
                      handleHide={handleHide}
                    />
                  )}
                </>
              )}
            />
          ) : broadcasterInfo.length === 2 ? (
            <BroadcasterList>
              {broadcasterInfo.map((b) => (
                <BroadcasterItem>
                  <a
                    href={b.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Logo abbreviation={b.abbreviation} name={b.name} />
                    <NewTabCopy />
                    <ExternalIcon />
                  </a>
                </BroadcasterItem>
              ))}
            </BroadcasterList>
          ) : (
            <BroadcasterBox>
              <a
                href={broadcasterInfo[0].url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <Logo
                  abbreviation={broadcasterInfo[0].abbreviation}
                  name={broadcasterInfo[0].name}
                />
                <NewTabCopy />
                <ExternalIcon />
              </a>
            </BroadcasterBox>
          )}
        </Broadcaster>
      )}
    </StyledFixture>
  );
};

export default Fixture;
