import { size } from "polished";
import styled from "styled-components";
import BasePadlock from "../../../../img/icons/padlock.svg?react";
import { IStyledElementFixtureBar } from "./types";

export const StyledElementFixtureBar = styled.div<IStyledElementFixtureBar>`
  padding: 0;
  font-size: 1.1rem;
  line-height: 1;
  color: ${({ $isLive, $isFinished }) =>
    $isLive || $isFinished ? "white" : "inherit"};
  text-align: center;
  background: ${({ $isLive, $isFinished, theme }) =>
    $isLive
      ? theme.colors.pinkToOrange
      : $isFinished
      ? theme.colors.primary
      : theme.colors.purple10};
  border-radius: 0 0 0.3rem 0.3rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0.3rem ${(props) => props.theme.space[1]};
    font-size: ${(props) => props.theme.fontSizes[2]};
  }
`;

export const PadlockIcon = styled(BasePadlock)`
  ${size(7)}
  fill: currentcolor;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(8)}
  }
`;

export const PointsWrap = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: 0;
  }
`;
