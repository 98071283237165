import { size } from "polished";
import styled, { css } from "styled-components";
import Button from "../components/Button";
import Dialog from "../components/Dialog";
import DialogBody from "../components/DialogBody";
import DialogHeader from "../components/DialogHeader";
import Remove from "../img/icons/remove.svg?react";
import Tick from "../img/icons/tick.svg?react";

export const UnsavedChangesDialogControls = styled.div`
  display: flex;
  gap: 2rem;
`;

const UnsavedChangesIcon = css`
  ${size("15px")};
  margin-left: ${(props) => props.theme.space[1]};
`;

export const UnsavedChangesDialogConfirm = styled(Tick)`
  ${UnsavedChangesIcon}
`;

export const UnsavedChangesDialogDeny = styled(Remove)`
  ${UnsavedChangesIcon}
`;

export const UnsavedChangesDialogCopy = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export interface IUnsavedChangesDialog {
  handleConfirm: () => void;
  handleHide: () => void;
}

const UnsavedChangesDialog: React.FC<IUnsavedChangesDialog> = ({
  handleConfirm,
  handleHide,
}) => {
  return (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>
        You have unsaved changes
      </DialogHeader>
      <DialogBody isPadded={true}>
        <UnsavedChangesDialogCopy>
          Are you sure you want to leave this page?
        </UnsavedChangesDialogCopy>
        <UnsavedChangesDialogControls>
          <Button
            $isFullWidth
            $stretch
            $variant="tertiary"
            onClick={handleHide}
          >
            No
            <UnsavedChangesDialogDeny />
          </Button>
          <Button
            $isFullWidth
            $stretch
            $variant="secondary"
            onClick={handleConfirm}
          >
            Yes
            <UnsavedChangesDialogConfirm />
          </Button>
        </UnsavedChangesDialogControls>
      </DialogBody>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
