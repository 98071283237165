import React, { useEffect } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import Alert from "../components/Alert";
import ButtonLink from "../components/ButtonLink";
import { ChevronRight } from "../components/Chevrons";
import EventNavigator from "../components/EventNavigator";
import Fixtures from "../components/Fixtures";
import HelmetHead from "../components/HelmetHead";
import { NextIcon, PrevIcon } from "../components/Icons";
import { GridItem, GridWrapper, TwoCol50Grid } from "../components/Layout";
import LeaderboardAd from "../components/LeaderboardAd";
import Link from "../components/Link";
import { Pager, PagerItemNext } from "../components/Pager";
import PhaseNav from "../components/PhaseNav";
import PhaseNavigator from "../components/PhaseNavigator";
import RankBadge from "../components/RankBadge";
import ReportNameButton from "../components/ReportNameButton";
import { ReportNameButtonWrapStyles } from "../components/ReportNameButton/styles";
import StandingsHeading from "../components/Standings/StandingsHeading/StandingsHeading";
import StandingsInformation from "../components/Standings/StandingsInformation";
import StandingsRow from "../components/Standings/StandingsRow";
import Table from "../components/Table";
import { useAppDispatch, useAppSelector } from "../rtk-core/src/app/hooks";
import { RootState } from "../rtk-core/src/app/store";
import { eventsApi } from "../rtk-core/src/features/api/events";
import { useLazyFetchClassicLeagueStandingsQuery } from "../rtk-core/src/features/api/leagues";
import { getEntry } from "../rtk-core/src/features/entries";
import {
  IEvent,
  getActiveEvent,
  getCurrentEvent,
  getCurrentEventStatus,
} from "../rtk-core/src/features/events";
import {
  IClassicStanding,
  getClassicLeague,
  getClassicStandings,
} from "../rtk-core/src/features/leagues";
import {
  getDefaultEventForPhase,
  getEventPhase,
  getOverallPhase,
  getPhasesById,
  getPhasesByStartAndStopEventId,
} from "../rtk-core/src/features/phases";
import { getPlayerData } from "../rtk-core/src/features/player";
import { getEventUrlForEntry } from "../utils/entries";
import { getEloRank, getRankFriendlyName } from "../utils/ranks";

const StyledChevron = styled(ChevronRight)`
  min-height: 12px;
`;

const ButtonWrap = styled.div`
  ${ReportNameButtonWrapStyles}
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-direction: row;
    justify-content: end;
  }
`;

const StandingsTable = styled(Table)`
  table-layout: fixed;
  border: none;

  thead th {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }

  tbody tr {
    td {
      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.purple10};
      }

      &:last-child {
        border-right: 1px solid ${({ theme }) => theme.colors.purple10};
      }
    }

    &:last-child {
      td {
        border-bottom: 1px solid ${({ theme }) => theme.colors.purple10};

        &:first-child {
          border-bottom-left-radius: ${(props) => props.theme.radii[2]};
        }

        &:last-child {
          border-bottom-right-radius: ${(props) => props.theme.radii[2]};
        }
      }
    }
  }
`;

const PosCol = styled.th`
  width: 15%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 8%;
  }
`;

const TeamCol = styled.th`
  width: 81%;
`;

const PtsCol = styled.th`
  width: 30%;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 11%;
  }
`;

const PtsLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
`;

const RankBadgeWrap = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[2]};
`;

const StandingsClassic: React.FC = () => {
  /**
   * Note:
   * - Before a league has started, system leagues do not display any information. It will be
   *   a blank table until ranking commences for that event.
   *   This is due to the computational expense of determining the size of these leagues,
   *   which could potentially contain millions of teams.
   *
   * - Until a league is closed for entry, entries will either have a '-' for those without a rank
   *   or score.
   *
   * - Once a league has started and is no longer open for entry, then entries who have not entered
   *   a team will have a DNP (did not play associated)
   *
   * - After a league has started, both system leagues and large private leagues
   *   (with more than 512 teams) do not show statuses like "has not entered," "did not play,"
   *   or "to be added."
   *
   * This design choice optimizes performance and user experience by avoiding
   * the processing of excessively large datasets.
   */
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { leagueId, phaseId } =
    useParams<{
      leagueId: string;
      phaseId?: string;
    }>();
  const leagueNumber = leagueId ? parseInt(leagueId, 10) : 0;
  const phaseNumber = phaseId ? parseInt(phaseId, 10) : 0;

  const query = new URLSearchParams(location.search);

  const pageNewEntries = parseInt(query.get("page_new_entries") || "1", 10);
  const pageStandings = parseInt(query.get("page_standings") || "1", 10);

  // Assign 'state' variables at once
  const { league, now, player, statusData, activeEvent } = useAppSelector(
    (state: RootState) => ({
      now: getCurrentEvent(state),
      league: getClassicLeague(state, leagueNumber),
      player: getPlayerData(state),
      statusData: getCurrentEventStatus(state),
      activeEvent: getActiveEvent(state),
    })
  );
  const reduxDispatch = useAppDispatch();
  const [fetchClassicStandingsTrigger] =
    useLazyFetchClassicLeagueStandingsQuery();

  const entry = useAppSelector((state: RootState) =>
    player && player.entry ? getEntry(state, player.entry) : null
  );

  const phasesById = useAppSelector(getPhasesById);
  const overallPhase = useAppSelector((state) => getOverallPhase(state));
  const activePhase = useAppSelector((state) =>
    phaseNumber > 0
      ? phasesById[phaseNumber]
      : getPhasesByStartAndStopEventId(state, activeEvent.id, activeEvent.id)[0]
  );

  const nextPhase = useAppSelector((state) =>
    getEventPhase(state, activePhase.id + 1)
  );
  const defaultEventNextPhase = useAppSelector((state) =>
    nextPhase ? getDefaultEventForPhase(state, nextPhase.id) : null
  );

  const prevPhase = useAppSelector((state) =>
    getEventPhase(state, activePhase.id - 1)
  );
  const defaultEventPrevPhase = useAppSelector((state) =>
    prevPhase ? getDefaultEventForPhase(state, prevPhase.id) : null
  );

  const standings = useAppSelector((state) =>
    getClassicStandings(state, leagueNumber, activePhase.id, pageStandings)
  );

  useEffect(() => {
    if (leagueNumber) {
      fetchClassicStandingsTrigger({
        leagueId: leagueNumber,
        phaseId: activePhase.id,
        pageNewEntries: pageNewEntries,
        pageStandings: pageStandings,
      });
    }
    reduxDispatch(eventsApi.endpoints.getEventStatus.initiate());
  }, [
    dispatch,
    leagueNumber,
    pageNewEntries,
    pageStandings,
    fetchClassicStandingsTrigger,
    reduxDispatch,
    activePhase.id,
  ]);

  const buildLink = (
    eventId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) => {
    let url = `/leagues/${leagueId}/standings/c/${eventId}`;

    if (phaseId > 0) {
      url += `/${phaseId}`;
    }

    url += `?page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`;

    return url;
  };

  const getEventUrl = (event: IEvent) => {
    return `/leagues/${leagueId}/standings/c/${event.id}`;
  };

  const goToNextPhase = () => {
    if (nextPhase && defaultEventNextPhase) {
      navigate(
        buildLink(defaultEventNextPhase.id, nextPhase.id, pageNewEntries, 1)
      );
    }
  };

  const goToPrevPhase = () => {
    if (prevPhase && defaultEventPrevPhase) {
      navigate(
        buildLink(defaultEventPrevPhase.id, prevPhase.id, pageNewEntries, 1)
      );
    }
  };

  if (!league) {
    return null;
  }

  const started = now && league.start_event <= now.id;

  // if event is released and you cant enter, its therefore closed and
  // therefore entries who arent entered did not play (DNP)
  const eventClosed = !activeEvent.can_enter && activeEvent.released;

  // check if did not plays are present for the key, can only appear for closed events
  // where entries
  const hasDidNotPlay =
    standings && eventClosed
      ? standings.results.some((result) => result.has_played === false)
      : false;

  function renderRank(ls: IClassicStanding) {
    if (!ls.has_played) {
      // if has not played and the event has closed permanently for entry,
      // the entry did not play (DNP) otherwise, still a possibility so render '-'
      return eventClosed ? "DNP" : "-";
    } else {
      // check for rank and return otherwise, return '-' as not ranked yet
      return ls.rank ? ls.rank.toLocaleString() : "-";
    }
  }

  function getEntryEventUrl(ls: IClassicStanding) {
    return entry && entry.id === ls.entry
      ? getEventUrlForEntry(entry, activeEvent, player)
      : `/entry/${ls.entry}/event/${activeEvent.id}`;
  }

  const hasPreviousEvent = activeEvent.id > league.start_event;

  return (
    <GridWrapper>
      <div className="mt-3">
        <LeaderboardAd
          id="div-gpt-ad-1501757861635-0"
          slot="DesktopFPLLeague"
          targetValue={`fplClassicLeague${league.id}`}
        />
      </div>
      <TwoCol50Grid>
        <HelmetHead
          title={"Create & Join Fantasy Football Leagues | PL Challenges"}
          description={
            "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
          }
        />
        <GridItem>
          <div className="mt-3">
            <PhaseNav />
          </div>
          {/* Check if phase is representative of an event */}
          {activePhase.start_event === activePhase.stop_event && (
            <div className="-mb-3 mt-3">
              <EventNavigator
                getEventUrl={getEventUrl}
                hasPreviousEvent={hasPreviousEvent}
              />
            </div>
          )}
          {activePhase.start_event !== activePhase.stop_event &&
            overallPhase?.id !== activePhase.id && (
              <div className="-mb-3 mt-3">
                <PhaseNavigator
                  activePhaseId={activePhase.id}
                  nextOnClick={() => goToNextPhase()}
                  prevOnClick={() => goToPrevPhase()}
                />
              </div>
            )}
        </GridItem>
        <GridItem>
          <StandingsHeading
            type="classic"
            league={league}
            leagueNumber={leagueNumber}
          />
        </GridItem>
        {/* currently turned off until we decide if we want to use this */}
        {/* {league.start_event > 1 && (
            <div className="m-2">
              <Alert>
                {started
                  ? `League scoring started in Gameweek ${getShortNameFromId(
                      league.start_event,
                      true
                    )}`
                  : `League scoring will start in Gameweek ${getShortNameFromId(
                      league.start_event,
                      true
                    )}`}
              </Alert>
            </div>
          )} */}
        {started && statusData && statusData.leagues === "Updating" && (
          <GridItem>
            <Alert>
              League tables are currently being re-calculated. The table below
              may still contain old data.
            </Alert>
          </GridItem>
        )}
        <GridItem>
          <div className="-mb-3">
            <StandingsInformation
              standings={standings}
              didNotPlay={hasDidNotPlay}
            />
          </div>
        </GridItem>
        <GridItem>
          <StandingsTable>
            <thead>
              <tr>
                <PosCol scope="col">Pos</PosCol>
                <TeamCol scope="col">Team</TeamCol>
                <PtsCol scope="col">Points</PtsCol>
              </tr>
            </thead>
            <tbody>
              {standings &&
                standings.results.map((ls) => {
                  const eloData = {
                    elo_badge: ls.elo_badge,
                    elo_events_until_ranked: ls.elo_events_until_ranked,
                  };
                  const rankFriendlyName = getRankFriendlyName(eloData);
                  const eloRank = getEloRank(eloData);
                  return (
                    <StandingsRow
                      mine={player !== null && player.entry === ls.entry}
                      // Only did not play if the event is closed, otherwise
                      // just unranked
                      didNotPlay={!ls.has_played && eventClosed}
                      key={ls.entry}
                    >
                      <td>
                        <div className="flex justify-center">
                          <div className="mr-1">{renderRank(ls)}</div>
                        </div>
                      </td>
                      <td>
                        <RankBadgeWrap>
                          <div>
                            <RankBadge
                              name={rankFriendlyName}
                              rank={eloRank}
                              size={34}
                            />
                          </div>
                          <div>
                            {activeEvent ? (
                              <Link to={getEntryEventUrl(ls)}>
                                <strong>{ls.entry_name}</strong>
                              </Link>
                            ) : (
                              <strong>{ls.entry_name}</strong>
                            )}
                            <br />
                            {ls.player_name}
                          </div>
                        </RankBadgeWrap>
                      </td>
                      <td>
                        {ls.has_played ? (
                          <PtsLink to={getEntryEventUrl(ls)}>
                            {/* consistency with rank column */}
                            {ls.rank ? `${ls.total} pts` : "-"}
                            <StyledChevron />
                          </PtsLink>
                        ) : (
                          // As entry hasn't played, no chevron/link
                          // to the team available
                          "-"
                        )}
                      </td>
                    </StandingsRow>
                  );
                })}
            </tbody>
          </StandingsTable>
        </GridItem>
        {(standings?.has_next || pageStandings > 1) && (
          <GridItem>
            <Pager>
              <div>
                {pageStandings > 1 && (
                  <ButtonLink
                    to={buildLink(
                      activeEvent.id,
                      phaseNumber,
                      pageNewEntries,
                      pageStandings - 1
                    )}
                    $variant="tertiary"
                    $isFullWidth={true}
                  >
                    <PrevIcon />
                    <span>Previous</span>
                  </ButtonLink>
                )}
              </div>
              <PagerItemNext>
                {standings?.has_next && (
                  <ButtonLink
                    to={buildLink(
                      activeEvent.id,
                      phaseNumber,
                      pageNewEntries,
                      pageStandings + 1
                    )}
                    $variant="tertiary"
                  >
                    <span>Next</span>
                    <NextIcon />
                  </ButtonLink>
                )}
              </PagerItemNext>
            </Pager>
          </GridItem>
        )}
        {/* Only show the the Report button on invitation leagues */}
        {league && league.league_type === "x" && (
          <GridItem>
            <ButtonWrap>
              <ReportNameButton league={league} />
            </ButtonWrap>
          </GridItem>
        )}
        <GridItem>
          <Fixtures eventId={activeEvent.id} />
        </GridItem>
      </TwoCol50Grid>
    </GridWrapper>
  );
};

export default StandingsClassic;
