import { hiDPI } from "polished";
import styled from "styled-components";
import pattern1_2x from "../../img/pattern/pattern-1-1236.png";
import pattern1 from "../../img/pattern/pattern-1-874.png";

const GameHeader = styled.div`
  background-image: url(${pattern1}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.fantasy},
      ${(props) => props.theme.colors.blueLight}
    );
  background-repeat: no-repeat;
  background-position: right -55px bottom -75px, 0;
  background-size: 378px 538px, auto;

  ${hiDPI(2)} {
    background-image: url(${pattern1_2x}),
      linear-gradient(
        to right,
        ${(props) => props.theme.colors.fantasy},
        ${(props) => props.theme.colors.blueLight}
      );
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    background-position: 98% -500px, 0;
    background-size: 666px 947px, auto;
  }
`;

export default GameHeader;
