import styled, { css } from "styled-components";
import SortIcon from "../../img/icons/sort.svg?react";
import React, { useState, useEffect } from "react";
import { formatRawAsDate, formatRawAsISO } from "../../utils/datetime";

const StyledFDRHeadingCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 63px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const EventName = styled.h3`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.6rem;
`;

const EventTime = styled.time`
  font-size: 1.2rem;
`;

interface IIconWrapperProps {
  isActive: boolean;
  isRotated: boolean;
}

const StyledSortIcon = styled(SortIcon)`
  fill: #000;
`;

const StyledSortIconWrapper = styled.div<IIconWrapperProps>`
  padding-top: 2px;
  padding-bottom: 2px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ isActive }) =>
    isActive === true &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};

      ${StyledSortIcon} {
        fill: ${({ theme }) => theme.colors.white};
      }
    `}

  ${({ isRotated }) =>
    isRotated === true &&
    css`
      transform: rotate(180deg) scaleX(-1);
    `}
`;

type Props = {
  id: number;
  eventName: string;
  activeEventId: any;
  eventDeadline?: string;
  onHandleClick: (eventId: number, sortType: string) => void;
};

const FDRHeadingCell: React.FC<Props> = ({
  id,
  eventName = "Heading",
  eventDeadline,
  onHandleClick,
  activeEventId,
}) => {
  const [active, setActive] = useState(false);
  const [sortOrderAsc, setOrderA] = useState(false);
  const [activeClickIndex, handleClick] = useState(0);

  useEffect(() => {
    if (activeEventId !== id) {
      setActive(false);
      setOrderA(false);
      handleClick(0);
    }
  }, [id, activeEventId]);

  function handleHeadingClick() {
    const incrementIndex = activeClickIndex + 1;
    if (incrementIndex === 1) {
      setActive(true);
      setOrderA(true);
      handleClick(incrementIndex);
      // sort by team difficulty
      onHandleClick(id, "asc");
    }

    if (incrementIndex === 2) {
      setOrderA(false);
      handleClick(incrementIndex);
      // sort by team difficulty
      onHandleClick(id, "dsc");
    }

    if (incrementIndex === 3) {
      setActive(false);
      setOrderA(false);
      handleClick(0);
      // Sort by team title
      onHandleClick(id, "a-z");
    }
  }

  return (
    <StyledFDRHeadingCell onClick={handleHeadingClick}>
      <StyledHeading>
        <EventName>{eventName}</EventName>
        {eventDeadline && (
          <EventTime dateTime={formatRawAsISO(eventDeadline)}>
            {formatRawAsDate(eventDeadline)}
          </EventTime>
        )}
      </StyledHeading>
      <StyledSortIconWrapper isActive={active} isRotated={sortOrderAsc}>
        <StyledSortIcon />
      </StyledSortIconWrapper>
    </StyledFDRHeadingCell>
  );
};

export default FDRHeadingCell;
