import styled from "styled-components";

export const RankName = styled.div`
  padding: ${(props) => props.theme.space[1]};
  font-size: ${(props) => props.theme.fontSizes[0]};
  font-weight: 800;
  color: ${(props) => props.theme.colors.fantasy};
`;

export const RankNameWrapper = styled.div`
  margin-top: ${(props) => props.theme.space[2]};
  text-align: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.radii[1]};
`;
