import { size } from "polished";
import styled, { css } from "styled-components";
import BaseMovementIcon from "../../img/icons/movement.svg?react";
import BaseNewIcon from "../../img/icons/new.svg?react";

export const NewIcon = styled(BaseNewIcon)`
  fill: ${({ theme }) => theme.colors.darkGrey};
  ${size(12)}
`;

export const MovementIcon = styled(BaseMovementIcon)<{ status: string }>`
  ${(props) =>
    props.status &&
    css`
      color: ${props.theme.colors.movement[props.status].color};
      fill: ${props.theme.colors.movement[props.status].bg};
      ${size(12)}
    `}

  ${(props) =>
    props.status === "down" &&
    css`
      transform: rotate(180deg);
    `}
`;
