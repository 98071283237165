import styled from "styled-components";
import { StyledEventCard } from "../EventCard/styles";

export const CarouselContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[3]};
  align-items: stretch;
`;

export const CarouselSlide = styled.div`
  display: flex;
  flex: 0 0 72%;
  min-width: 0;

  ${StyledEventCard} {
    flex: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 44%;
  }
`;

export const NavButtons = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[1]};
  justify-content: end;
  margin-bottom: ${(props) => props.theme.space[2]};
`;

export const NavButton = styled.button`
  padding: 0.7rem 0.6rem;
  line-height: 1;
  background-color: ${(props) => props.theme.colors.primary};
  border: 0;
  border-radius: ${(props) => props.theme.radii[1]};

  &:disabled {
    opacity: 0.6;
  }
`;
