import styled from "styled-components";

export const StyledRanksExplained = styled.div`
  overflow: hidden;
  border-radius: 16px;

  /* Hide system-settings scroll bar on large screens */
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    ::-webkit-scrollbar {
      width: 0;
      visibility: hidden;
    }
  }
`;
