import { rgba, size } from "polished";
import styled, { css } from "styled-components";
import BaseCog from "../../../img/icons/cog.svg?react";
import Players from "../../../img/icons/players.svg?react";
import { ChevronRight } from "../../Chevrons/Chevrons";
import Link, { LinkStyles } from "../../Link";
import LinkButton from "../../LinkButton";
import { Ellipsis } from "../../Utils";
import {
  LeagueInfoProps,
  LeagueNameProps,
  LeagueRowWrapperProps,
} from "./types";

export const ActionList = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
`;

export const ChevronIcon = styled(ChevronRight)`
  ${size(16)};
`;

export const Cog = styled(BaseCog)`
  ${size(14)};
  margin-right: ${({ theme }) => theme.space[1]};
`;

export const Label = styled.span`
  ${LinkStyles}
  align-self: baseline;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const LeagueInfo = styled.div<LeagueNameProps>`
  display: inherit;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]};
  justify-content: flex-start;

  ${({ isResponsive, theme }) =>
    isResponsive &&
    css`
      @media (min-width: ${theme.breakpoints[3]}) {
        flex: 1 1 50%;
      }
    `};
`;

export const LeagueInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const LeagueName = styled(Ellipsis)`
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  letter-spacing: -0.04px;
`;

export const LeagueRowWrapper = styled.div<LeagueRowWrapperProps>`
  display: flex;
  flex-flow: column wrap;
  gap: ${({ theme }) => theme.space[2]};
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[3]};
  background-color: ${({ theme }) => theme.colors.purple5};
  border: 1px solid ${({ theme }) => theme.colors.purple10};
  border-radius: ${({ theme }) => theme.radii[2]};

  ${({ isResponsive, theme }) =>
    isResponsive &&
    css`
      @media (min-width: ${theme.breakpoints[3]}) {
        flex-flow: row nowrap;
      }
    `}
`;

export const LeagueStat = styled.div<LeagueInfoProps>`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;

  ${({ isResponsive, theme }) =>
    isResponsive &&
    css`
      @media (min-width: ${theme.breakpoints[3]}) {
        flex: 0.35;
        justify-content: space-between;
      }
    `}

  &:not(:nth-last-child(2))::after {
    position: absolute;
    top: ${({ theme }) => theme.space[1]};
    right: ${({ theme }) => theme.space[2]};
    bottom: ${({ theme }) => theme.space[1]};
    display: block;
    width: 1px;
    content: "";
    background: ${({ theme }) => rgba(theme.colors.primary, 0.1)};

    ${({ isResponsive, theme }) =>
      isResponsive &&
      css`
        @media (min-width: ${theme.breakpoints[3]}) {
          top: ${({ theme }) => theme.space[2]};
          right: ${({ theme }) => theme.space[5]};
          bottom: ${({ theme }) => theme.space[2]};
        }
      `}
  }
`;

export const LeagueStatsWrap = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: end;
`;

export const PlayerCountWrap = styled.div`
  display: flex;
`;

export const PlayersIcon = styled(Players)`
  ${size(16)};
  align-self: center;
  margin-right: ${({ theme }) => theme.space[1]};
`;

export const RankSuffix = styled.sup`
  top: -0.3em;
`;

export const StatLabel = styled.div`
  font-size: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

export const StatValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  letter-spacing: -0.04px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

export const StatValueWrap = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[1]};
  align-items: center;
`;

export const StyledChevronRightLink = styled(Link)`
  align-self: center;
`;

export const StyledLinkButton = styled(LinkButton)`
  align-self: flex-end;
`;
