import styled, { css } from "styled-components";
import { IAlert } from "./types";

export const StyledAlert = styled.div<IAlert>`
  display: flex;
  gap: ${(props) => props.theme.space[1]};
  align-items: center;
  justify-content: ${({ $align = "center" }) => $align};
  padding: 1rem ${(props) => props.theme.space[2]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};

  ${({ $type = "default", theme }) =>
    $type === "error" &&
    css`
      color: white;
      background-color: ${theme.colors.error};
    `}

  ${({ $type = "default", theme }) =>
    $type === "success" &&
    css`
      color: ${theme.colors.primary};
      background-color: ${theme.colors.success};

      > svg {
        color: ${theme.colors.primary};
      }
    `}

  ${({ $copnr = null, theme }) =>
    ($copnr || $copnr === 0) &&
    css`
      color: ${theme.colors.elementStatus[$copnr].color};
      background-color: ${theme.colors.elementStatus[$copnr].bg};

      > svg {
        color: currentcolor;
        fill: ${theme.colors.elementStatus[$copnr].bg};
      }
    `}
`;

export const AlertItem = styled.p`
  margin-top: ${(props) => props.theme.space[1]};
  margin-bottom: ${(props) => props.theme.space[1]};
  color: currentcolor;
`;

export const AlertGroup = styled.div`
  margin-top: ${(props) => props.theme.space[3]};
  margin-bottom: ${(props) => props.theme.space[3]};
`;

export default StyledAlert;
