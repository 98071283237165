import { rgba } from "polished";
import styled, { css } from "styled-components";
import { SubStatus } from "../../rtk-core/src/features/myTeam/types";
import {
  ElementListOpp,
  ElementListStat,
  ElementListPrice,
} from "../ElementList/styles";
import Table from "../Table";

export const ElementTable = styled(Table)`
  margin-bottom: ${({ theme }) => theme.space[3]};
  white-space: nowrap;
  table-layout: fixed;

  th,
  td {
    padding: ${(props) => props.theme.space[2]};
  }

  thead {
    th {
      font-family: ${({ theme }) => theme.fonts.heavy};
      text-align: left;
    }

    ${ElementListStat}, ${ElementListPrice} {
      text-align: right;
    }

    ${ElementListOpp} {
      text-align: center;
    }
  }
`;

export const PrimaryCell = styled.td`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const PrimaryCellLined = styled(PrimaryCell)`
  ::after {
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 0;
    display: block;
    width: 2px;
    content: "";
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

interface IElementRowProps {
  variant?: SubStatus;
}

export const ElementRow = styled.tr<IElementRowProps>`
  td:first-child {
    padding: 0;
  }

  ${(props) =>
    props.variant === "instigator" &&
    css`
      background-color: ${rgba("yellow", 0.6)};
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      background-color: ${rgba("#f60", 0.6)};
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.6;
    `}
`;

export const BorderedCell = styled.td`
  position: relative;

  ::after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    display: block;
    width: 1px;
    content: "";
    background: ${({ theme }) => theme.colors.lightGrey};
    border-radius: 1px;
  }
`;
