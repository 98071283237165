import styled, { css } from "styled-components";
import ChevronDoubleLeftIcon from "../../img/icons/chevron-double-left.svg?react";
import ChevronDoubleRightIcon from "../../img/icons/chevron-double-right.svg?react";
import ChevronDownIcon from "../../img/icons/chevron-down.svg?react";
import ChevronLeftIcon from "../../img/icons/chevron-left.svg?react";
import ChevronRightIcon from "../../img/icons/chevron-right.svg?react";
import BaseNextIcon from "../../img/icons/next.svg?react";
import BasePrevIcon from "../../img/icons/previous.svg?react";

const ChevronStyles = css`
  vertical-align: middle;
  fill: currentcolor;
`;

export const ChevronDown = styled(ChevronDownIcon)`
  ${ChevronStyles}
`;

export const ChevronLeft = styled(ChevronLeftIcon)`
  ${ChevronStyles}
`;

export const ChevronRight = styled(ChevronRightIcon)`
  ${ChevronStyles}
`;

export const ChevronDoubleLeft = styled(ChevronDoubleLeftIcon)`
  ${ChevronStyles}
`;

export const ChevronDoubleRight = styled(ChevronDoubleRightIcon)`
  ${ChevronStyles}
`;

export const PrevIcon = styled(BasePrevIcon)`
  ${ChevronStyles}
  margin-right: 0.6rem;
`;

export const NextIcon = styled(BaseNextIcon)`
  ${ChevronStyles}
  margin-left: 0.6rem;
`;
