import { hideVisually, size, rgba } from "polished";
import * as React from "react";
import styled, { css } from "styled-components";

interface IStyledProps {
  disabled?: boolean;
  $isSmall?: boolean;
}

const Button = styled.button<IStyledProps>`
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  background-color: white;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 4px ${rgba("black", 0.35)};

  svg {
    ${size(16, 18)}
    vertical-align: middle;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.darkGrey};
      background-color: #e2e2e2;
    `}

  ${(props) =>
    props.$isSmall &&
    css`
      svg {
        ${size(10, 18)}
        vertical-align: middle;
      }
    `}
`;

const HiddenLabel = styled.span`
  ${hideVisually}
`;

interface IProps {
  actionMe: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  isSmall?: boolean;
  label: string;
}

const PaginatorButton: React.FC<IProps> = ({
  actionMe,
  children,
  disabled,
  isSmall,
  label,
}) => (
  <Button
    onClick={actionMe}
    type="button"
    disabled={disabled}
    $isSmall={isSmall}
  >
    {children}
    <HiddenLabel>{label}</HiddenLabel>
  </Button>
);

export default PaginatorButton;
