import { size } from "polished";
import styled, { css } from "styled-components";
import { IStyledFixtureStatsProps } from "./types";

export interface IFixtureProgress {
  $isFinal?: boolean;
  $isNext?: boolean;
  $isPassed: boolean;
  $isToCome: boolean;
  $isDeadline?: boolean;
}

export const FixtureDealineButton = styled.button`
  display: inline-flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[3]}`};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[1]};
  color: white;
  cursor: pointer;
  border: 0;
  border-radius: ${(props) => props.theme.radii[1]};

  > svg {
    display: inline-block;
    vertical-align: middle;
    fill: currentcolor;
  }

  ${({ theme }) =>
    css`
      background: ${theme.colors.purpleLight};
    `}
`;

export const StyledFixtures = styled.section`
  padding-bottom: ${({ theme }) => theme.space[5]};
  border-radius: 1rem;
`;

export const FixturesHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

export const Heading = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: 2.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.4rem;
  }
`;

export const DeadlineInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space[4]};
  font-size: 1.3rem;
`;

export const FixtureList = styled.ul`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  background-color: ${({ theme }) => theme.colors.purple5};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${({ theme }) => theme.space[4]} 7.2rem;
  }
`;

export const FinalDeadline = styled.h3`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: inherit;
`;

export const FinalDeadlineDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const Explainer = styled.a`
  margin-top: ${({ theme }) => theme.space[3]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const FixtureDayHeading = styled.h3<IFixtureProgress>`
  position: relative;
  padding-top: ${({ theme }) => theme.space[4]};
  padding-bottom: ${({ theme }) => theme.space[4]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  text-align: center;

  ${(props) =>
    css`
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 5px;
        width: 3px;
        content: "";
        background-color: ${props.$isPassed
          ? props.theme.colors.purple40
          : props.$isToCome
          ? props.theme.colors.purple20
          : "transparent"};
      }
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-bottom: ${({ theme }) => theme.space[2]};
    padding-left: ${({ theme }) => theme.space[6]};
    font-size: 2rem;
    text-align: left;
  }
`;

export const FixtureDay = styled.li`
  &:first-child {
    ${FixtureDayHeading} {
      &::before {
        content: none;
      }
    }
  }
`;

export const DeadlineFixtureList = styled.ul`
  padding: 0;
  list-style: none;
`;

export const FixtureButton = styled.button<IStyledFixtureStatsProps>`
  display: flex;
  width: 100%;
  padding: 0;
  font-size: inherit;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const FixtureStatsWrap = styled.div<IStyledFixtureStatsProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: ${({ theme }) => `${theme.space[3]} 5%`};
  border-color: ${({ theme }) => theme.colors.primary};
  border-style: solid;
  border-width: 1px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 2.5rem 15%;
  }
`;

export const FixtureStat = styled.li`
  margin-bottom: ${({ theme }) => theme.space[5]};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: 0 8px 12px 0 rgb(135 135 135 / 0.08);
`;

export const FixtureStatHeading = styled.h5`
  padding: 0.3rem ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.blueLight}
  );
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
`;

export const FixtureStatBody = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
`;

export const FixtureStatList = styled.ul`
  flex: 1;
  padding-right: ${({ theme }) => theme.space[2]};
  padding-left: ${({ theme }) => theme.space[2]};

  &:first-child {
    text-align: right;
    border-right: 1px solid #e2e2e2;
  }
`;

export const FixtureStatItem = styled.li`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  border-bottom: 1px solid #e2e2e2;

  &:last-child {
    border-bottom: 0;
  }
`;

export const FixtureItem = styled.li`
  margin-bottom: 0.1rem;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.radii[2]};
`;

export const ElementButton = styled.button`
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.black};
  background-color: transparent;
  border: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const IconWrap = styled.span`
  padding: 1.7rem 1rem;
  line-height: 1;
  background-color: ${({ theme }) => theme.colors.purple10};
  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  border-bottom-left-radius: ${({ theme }) => theme.radii[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 2.1rem 1.7rem;
  }
`;

export const KOTimeFixtures = styled.div<IFixtureProgress>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.space[3]};
  padding-left: ${({ theme }) => theme.space[6]};

  ${(props) =>
    !props.$isToCome &&
    css`
      &::before {
        position: absolute;
        top: 14px;
        bottom: 0;
        left: 5px;
        width: 3px;
        content: "";
        background-color: ${props.$isPassed
          ? props.theme.colors.purple40
          : props.theme.colors.purple20};
      }
    `}

  ${({
    $isFinal = false,
    $isNext = false,
    $isToCome,
    $isPassed,
    theme,
    $isDeadline,
  }) =>
    (!$isToCome || $isFinal) &&
    css`
      &::after {
        ${$isDeadline === false
          ? css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 5px;
              width: 3px;
              content: "";
              background-color: ${$isPassed
                ? theme.colors.purple40
                : theme.colors.purple20};
            `
          : css`
              ${size(10)}
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              content: "";
              background-color: ${$isPassed
                ? theme.colors.purple40
                : "transparent"};
              border: 2px solid
                ${$isPassed || $isNext
                  ? theme.colors.purple40
                  : theme.colors.purple20};
              border-radius: 50%;
            `}
      }
    `}
`;
