import { rgba } from "polished";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Heading, { IHeading } from "../../components/Heading";
import ChevronDown from "../../img/icons/chevron-down.svg?react";
import { ITeam } from "../../rtk-core/src/features/teams/types";
import Badge from "../Badge";
import Copy from "../Copy";

const StyledCollapsible = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  box-shadow: 0 1px 4px 0 ${rgba("black", 0.08)};

  > h5 {
    margin-bottom: 0;
  }
`;

const BadgeWrapper = styled.div`
  display: inline-block;
`;

const IconWrap = styled.div`
  align-self: stretch;
  padding: ${({ theme }) => theme.space[5]};
  background-color: #f8f8f8;
`;

interface IButtonProps {
  $team?: boolean;
}

const Button = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.4rem;
  color: inherit;
  text-align: left;
  cursor: pointer;
  background-color: white;
  border: 0;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &[aria-expanded="true"] {
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.fantasy},
      ${({ theme }) => theme.colors.blueLight}
    );
    ${IconWrap} {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      fill: white;
      transform: rotate(180deg);
    }
  }

  ${({ $team }) =>
    $team === true &&
    css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    `}
`;

const HeadingText = styled.span`
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
`;

const CollapsibleContent = styled.div`
  padding: ${({ theme }) => theme.space[2]};

  &[aria-hidden] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
`;

// Used to generate unique ids for collapsible content / aria controls
let lastId = 0;
const newId = (prefix = "ism-id") => `${prefix}${++lastId}`;

interface ICollapsible {
  headingText: string;
  initialOpen?: boolean;
  team?: ITeam;
  children: React.ReactNode;
}

type IProps = IHeading & ICollapsible;

const Collapsible = ({
  headingText,
  initialOpen,
  team,
  children,
  headingLevel = 3,
}: IProps) => {
  const [state, setState] = useState({ open: false });
  const [id, setId] = useState("");
  const toggle = () => {
    setState({ open: !state.open });
  };

  useEffect(() => {
    setId((id) => newId("ism-collapsible-"));
    if (initialOpen) {
      setState((state) => ({ ...state, open: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledCollapsible>
      <Heading headingLevel={headingLevel}>
        <Button
          type="button"
          onClick={toggle}
          aria-expanded={state.open}
          aria-controls={id}
          $team={typeof team !== "undefined"}
        >
          <IconWrap>
            <ChevronDown />
          </IconWrap>
          {team ? (
            <div>
              <BadgeWrapper data-testid="badge-wrapper">
                <Badge
                  team={team}
                  isPresentation={true}
                  sizes="(min-width: 1024px) 32px, 24px"
                />
              </BadgeWrapper>
              <HeadingText data-testid="title">{headingText}</HeadingText>
            </div>
          ) : (
            <HeadingText data-testid="title">{headingText}</HeadingText>
          )}
        </Button>
      </Heading>
      <CollapsibleContent
        id={id}
        aria-hidden={!state.open}
        data-testid="content"
      >
        <Copy>{children}</Copy>
      </CollapsibleContent>
    </StyledCollapsible>
  );
};

export default Collapsible;
