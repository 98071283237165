import styled from "styled-components";

export const ElementSingleFixture = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.9rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 1rem;
  }
`;

export const ElementMultiFixtures = styled.div`
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.7rem;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: 1rem;
  }
`;
