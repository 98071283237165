import styled from "styled-components";

export const ActionBar = styled.div`
  margin-top: ${(props) => props.theme.space[6]};
  margin-bottom: ${(props) => props.theme.space[6]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.8rem;
    justify-content: center;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
  margin: ${(props) => props.theme.space[3]} 0;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 50%;
    margin: 0;
  }
`;

export const FormationSelectWrap = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 23%;
  }
`;
