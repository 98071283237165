import styled from "styled-components";
import { ChevronDown } from "../../Chevrons";

export const FixtureDay = styled.h4`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[2]};
`;

export const FixtureWrap = styled.div`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[4]}`};
  margin-bottom: ${(props) => props.theme.space[4]};
  background-color: #fff;
  border: ${(props) => props.theme.borders[0]};
  border-radius: ${(props) => props.theme.radii[2]};
`;

export const ElementFixtureWrap = styled.div`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[4]}`};
`;

export const ExplainButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: 0;

  &[aria-expanded="true"] > ${ChevronDown} {
    transform: rotateX(180deg);
  }

  ${ChevronDown} {
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    transform: translateY(-50%);
  }
`;
