import styled from "styled-components";

const LinkButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: inherit;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export default LinkButton;
