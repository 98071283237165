import styled from "styled-components";
import { default as CountryFlag } from "react-world-flags";

export const StyledFlag = styled(CountryFlag)`
  height: 16px;
  margin: ${({ theme }) => theme.space[1]} 0;
`;

export const CustomFlagImg = styled.img`
  height: 16px;
  margin: ${({ theme }) => theme.space[1]} 0;
`;
