import * as React from "react";
import styled, { css } from "styled-components";

interface IStyledMediaProps {
  $centred: boolean | undefined;
}

const StyledMedia = styled.div<IStyledMediaProps>`
  display: flex;
  ${(props) =>
    props.$centred &&
    css`
      align-items: center;
    `}
`;

const Img = styled.div`
  margin-right: ${({ theme }) => theme.space[1]};
  line-height: 1;
`;

const Body = styled.div`
  flex: 1;

  /* For ElementInTable webname ellipsis */
  max-width: 100%;
  overflow: hidden;
`;

interface IMediaProps {
  img: React.ReactNode;
  centred?: boolean;
  children: React.ReactNode;
}

const Media: React.FC<IMediaProps> = ({ img, centred, children }) => (
  <StyledMedia $centred={centred}>
    <Img>{img}</Img>
    <Body>{children}</Body>
  </StyledMedia>
);

Media.defaultProps = {
  centred: false,
};

export default Media;
