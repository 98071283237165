import * as React from "react";
import { IEntry } from "../../rtk-core/src/features/entries";
import { CustomFlagImg, StyledFlag } from "./styles";

interface IProps {
  entry: IEntry;
  alt?: string;
}

const Flag: React.FC<IProps> = ({ entry, alt = "" }) => {
  const exclusionList = ["RUS", "BLR"];
  const entryLongCode = entry.player_region_iso_code_long;
  const gbPrefix = "GB_";

  if (exclusionList.includes(entryLongCode)) {
    return null;
  }

  const getFlagCode = () => {
    // Work around where GB countries need a prefix and we store the wrong ISO code for Wales and Scotland
    if (entryLongCode === "WAL") {
      return `${gbPrefix}WLS`;
    }
    if (entryLongCode === "SCO") {
      return `${gbPrefix}SCT`;
    }
    if (entryLongCode === "ENG") {
      return `${gbPrefix}ENG`;
    }

    return entryLongCode;
  };

  const flagCode = getFlagCode();
  const customFlagPath = `/img/flags`;

  if (entryLongCode === "NIR") {
    return (
      <CustomFlagImg
        src={`${customFlagPath}/${gbPrefix}${flagCode}.svg`}
        alt={alt}
      />
    );
  }

  return (
    <StyledFlag
      code={flagCode}
      alt={alt}
      // fallback to old gif if flag not found
      fallback={
        <CustomFlagImg
          src={`${customFlagPath}/${entry.player_region_iso_code_short}.gif`}
          alt={alt}
        />
      }
    />
  );
};

export default Flag;
