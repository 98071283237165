import styled, { css } from "styled-components";
import { IElementMultiPoints } from "./types";

export const ElementMultiPoints = styled.div<IElementMultiPoints>`
  font-size: 1.1rem;

  ${(props) =>
    !props.$allStarted &&
    css`
      padding-top: 0.2rem;
      padding-bottom: 0.1rem;
      font-size: 0.8rem;
    `}

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[2]};

    ${(props) =>
      !props.$allStarted &&
      css`
        padding: 0;
      `}
  }
`;
