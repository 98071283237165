import { rgba, size } from "polished";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import WebViewContext from "../../contexts/WebViewContext";
import AppleLogo from "../../img/social/apple.svg?react";
import FacebookLogo from "../../img/social/facebook.svg?react";
import GoogleLogo from "../../img/social/google.svg?react";
import TwitterLogo from "../../img/social/twitter.svg?react";
import { getPlayerData } from "../../rtk-core/src/features/player/playerSlice";
import Alert from "../Alert";
import Button from "../Button";
import { ButtonHyperlink } from "../ButtonLink";
import { ChevronRight } from "../Chevrons";
import Copy from "../Copy";
import { InputField } from "../FieldRenderers/FieldRenderers";
import TextDivider from "../TextDivider/TextDivider";
import {
  LoginBox,
  LoginControls,
  LoginForm,
  LoginHyperlink,
  LoginItem,
  LoginTitle,
  SocialLogin,
} from "./styles";
import { ISocialButtonStyleProps } from "./types";

const StyledSocialButton = styled.a<ISocialButtonStyleProps>`
  display: block;
  padding: 0 ${({ theme }) => theme.space[2]};
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 4.4rem;
  color: ${(props) => props.theme.colors.social[props.$network].color};
  text-align: center;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.social[props.$network].bg};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: 0 1px 8px 0 ${rgba("black", 0.12)};

  > svg {
    ${size(18)}
    vertical-align: middle;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SocialButtonText = styled.span`
  margin-left: ${({ theme }) => theme.space[2]};
`;

const SocialName = styled.span`
  text-transform: capitalize;
`;

interface ISocialButtonProps {
  link: string;
  logo: React.ReactNode;
  network: string;
}

const SocialButton: React.FC<ISocialButtonProps> = ({
  link,
  logo,
  network,
}) => (
  <StyledSocialButton href={link} $network={network}>
    {logo}
    <SocialButtonText>
      Login with <SocialName>{network}</SocialName>
    </SocialButtonText>
  </StyledSocialButton>
);

interface IOwnProps {
  usersError?: string;
}

type Props = IOwnProps;

const Login: React.FC<Props> = ({ usersError = "" }) => {
  const { isWebView } = useContext(WebViewContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorRef = useRef<HTMLInputElement | null>(null);
  const player = useSelector(getPlayerData);

  useEffect(() => {
    if (usersError) {
      errorRef.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [usersError]);

  if (player && player.id) {
    return (
      <Copy>
        <p>
          You are logged in as {player.first_name} {player.last_name}.
        </p>
      </Copy>
    );
  }

  // Constants to help build urls
  const app = "plw-web";
  const plusers = import.meta.env.VITE_PLUSERS_BASE || "";
  const redirect = `${window.location.protocol}//${window.location.host}/`;
  const register = `${plusers}/users/register/personal?app=${app}&redirect_uri=${redirect}`;
  const passLook = `${plusers}/accounts/password-reset/`;
  const makeSocialLogin = (net: string) =>
    `${plusers}/accounts/${net}/login/?app=${app}&redirect_uri=${redirect}`;

  return (
    <LoginBox>
      {isWebView ? (
        <ButtonHyperlink
          href="pulse:deep-link:football/sign-in"
          $isFullWidth={true}
        >
          Sign in/Register to Play
        </ButtonHyperlink>
      ) : (
        <>
          {usersError && (
            <div>
              {usersError && (
                <div
                  className="mb-2"
                  role="status"
                  aria-live="polite"
                  ref={errorRef}
                >
                  <Alert $type="error">{usersError}</Alert>
                </div>
              )}
            </div>
          )}
          <LoginForm
            method="POST"
            action={`${plusers}/accounts/login/`}
            onSubmit={() => setIsSubmitting(true)}
          >
            <LoginTitle>Login</LoginTitle>
            <LoginItem>
              <InputField
                id="loginUsername"
                name="login"
                type="email"
                label="Email"
              />
            </LoginItem>
            <LoginItem>
              <InputField
                id="loginLoginWrap"
                name="password"
                type="password"
                label="Password"
              />
            </LoginItem>
            <LoginItem>
              <LoginControls>
                <Button
                  type="submit"
                  $isFullWidth={true}
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>
              </LoginControls>
            </LoginItem>
            <LoginItem>
              <LoginControls>
                <ButtonHyperlink
                  href={passLook}
                  $variant="clear"
                  $isFullWidth={true}
                >
                  Forgot password?
                  <ChevronRight />
                </ButtonHyperlink>
              </LoginControls>
            </LoginItem>
            <input type="hidden" name="app" value={app} />
            <input type="hidden" name="redirect_uri" value={redirect} />
          </LoginForm>

          <TextDivider>Or you can</TextDivider>

          {/* Social */}
          <SocialLogin>
            <LoginHyperlink href={register} $isFullWidth={true}>
              Create a free account
              <ChevronRight />
            </LoginHyperlink>
            <SocialButton
              link={makeSocialLogin("facebook")}
              network="facebook"
              logo={<FacebookLogo />}
            />
            <SocialButton
              link={makeSocialLogin("twitter")}
              network="x"
              logo={<TwitterLogo />}
            />
            <SocialButton
              link={makeSocialLogin("google")}
              network="google"
              logo={<GoogleLogo />}
            />
            <SocialButton
              link={makeSocialLogin("apple")}
              network="apple"
              logo={<AppleLogo />}
            />
          </SocialLogin>
        </>
      )}
    </LoginBox>
  );
};

export default Login;
