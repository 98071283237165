import { hiDPI } from "polished";
import styled from "styled-components";
import defaultAsset1360 from "../../img/pattern/default-asset-1360.png";
import defaultAsset2720 from "../../img/pattern/default-asset-2720.png";

export const ChallengeBlock = styled.div`
  /* https://www.figma.com/file/Dpq2rwOxK4WTHMPewJl2mR/FPL-Challenge%3A-Development-designs?type=design&node-id=3259-359911&mode=design&t=7UA2Nhm1IYXC0Dag-4 */
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  background-image: url(${defaultAsset1360}),
    linear-gradient(
      to right,
      ${({ theme }) => theme.colors.fantasy},
      ${({ theme }) => theme.colors.blueLight}
    );
  background-repeat: no-repeat;
  background-position: top left, 0;
  border-radius: ${(props) => props.theme.radii[4]};

  ${hiDPI(2)} {
    background-image: url(${defaultAsset2720}),
      linear-gradient(
        to right,
        ${({ theme }) => theme.colors.fantasy},
        ${({ theme }) => theme.colors.blueLight}
      );
    background-size: 1360px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[6]};
  }
`;

export const ChallengeBlockBody = styled.div`
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  background-image: linear-gradient(to right, #05f182, #00ece3);
  border-radius: ${(props) => props.theme.radii[2]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[6]};
  }
`;

export default ChallengeBlock;
