import { rgba, size } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ISeasonStats } from "./types";

export const StyledPlayerOverview = styled.div`
  padding: ${(props) => props.theme.space[2]};
  border: 1px solid ${(props) => props.theme.colors.purple5};
  border-radius: ${(props) => props.theme.radii[2]};
`;

export const RankHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${(props) => props.theme.space[2]};
`;

export const RankHeaderBadge = styled.div`
  ${size(94)}
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    ${size(128)}
  }
`;

export const TeamName = styled.h2`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
  line-height: 1.2;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: 3rem;
  }
`;

export const PlayerDetailList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.space[6]};
  color: ${(props) => props.theme.colors.purple60};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    font-size: ${(props) => props.theme.fontSizes[3]};
  }
`;

export const PlayerDetailItem = styled.li`
  &:not(:first-child) {
    margin-left: 2rem;
    list-style-type: disc;
  }
`;

export const OverviewBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[3]};
`;

export const SeasonStatsWrap = styled.div`
  padding: ${(props) => props.theme.space[3]};
  text-align: center;
  background-color: ${(props) => props.theme.colors.purple5};
  border-radius: ${(props) => props.theme.space[2]};
`;

export const SeasonStats = styled.div<ISeasonStats>`
  display: flex;
  gap: ${(props) => props.theme.space[3]};

  ${({ $isSidebar, theme }) =>
    !$isSidebar &&
    css`
      @media (min-width: ${theme.breakpoints[4]}) {
        width: 40%;
        margin-right: auto;
        margin-left: auto;
      }
    `}
`;

export const SeasonStatsMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[3]};
`;

export const SeasonStatsSecondary = styled.div`
  flex: 2;
  padding-right: ${(props) => props.theme.space[3]};
`;

export const SeasonStat = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => rgba(props.theme.colors.black, 0.1)};
  }
`;

export const SeasonStatHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 2;
`;

export const SeasonStatValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const SeasonStatMainHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1.2;
`;

export const SeasonStatMainValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 4rem;
  line-height: 1;
`;

export const StatLink = styled(Link)`
  display: flex;
  gap: ${(props) => props.theme.space[1]};
  align-items: center;
  color: currentcolor;
  text-decoration: none;
`;

export const LeagueLinks = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[2]};
`;

// LeagueLinksItem will change height if long club / country names cause wrapping so both link divs are the same height
export const LeagueLinksItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.purple5};
  border-radius: ${(props) => props.theme.radii[2]};
`;

export const LeagueLink = styled(Link)`
  display: flex;
  gap: ${(props) => props.theme.space[1]};
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[2]};
  font-family: ${(props) => props.theme.fonts.bold};
  color: currentcolor;
  text-decoration: none;

  /* Limits width of name spans to keep styling consistent when long text wraps */
  span {
    max-width: 70%;
    padding: ${(props) => props.theme.space[1]};
  }

  background-color: ${(props) => props.theme.colors.purple5};
  border-radius: ${(props) => props.theme.radii[2]};
`;
