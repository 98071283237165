import styled from "styled-components";

export const StyledELementList = styled.div`
  border: ${(props) => props.theme.borders[0]};
  border-radius: ${(props) => props.theme.radii[3]};
`;

export const Form = styled.form`
  padding: 0 ${(props) => props.theme.space[3]};
`;

export const ElementsShown = styled.div`
  margin: ${(props) => props.theme.space[3]};
`;

export const ElementTypeHeading = styled.h4`
  margin-bottom: 0.6rem;
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2rem;
`;

export const ElementTypeButton = styled.button`
  padding: 0;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const ElementListStatus = styled.th`
  width: 10%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 10%;
  }
`;

export const ElementListElement = styled.th`
  width: 43%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 32%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 42%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[6]}) {
    width: 44%;
  }
`;

export const ElementListOpp = styled.th`
  width: 23%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 22%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    width: 20%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[6]}) {
    width: 18%;
  }
`;

export const ElementListStat = styled.th`
  width: 17%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 18%;
  }
`;

export const ElementListPrice = styled.th`
  width: 17%;

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 18%;
  }
`;

export const ButtonWrap = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    display: none;
  }
`;

export const ElementListFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space[2]};
`;
