import { hideVisually, hiDPI } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import Close from "../../img/icons/close.svg?react";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import pattern1_874 from "../../img/pattern/pattern-1-874.png";
import Button from "../Button";

export const CloseIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.primary};
`;

const CloseLabel = styled.span`
  ${hideVisually}
`;

export const CloseButton = styled.button`
  padding: ${({ theme }) => theme.space[2]};
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const CloseButtonWrap = styled.div`
  padding: ${({ theme }) => theme.space[5]};
`;

const StyledHeader = styled.div<IStyledHeaderProps>`
  display: flex;
  justify-content: space-between;
  background-image: url(${pattern1_874}),
    linear-gradient(
      to right,
      ${(props) => props.theme.colors.fantasy},
      ${(props) => props.theme.colors.blueLight}
    );
  background-repeat: no-repeat;
  background-position: right -145px bottom -195px, 0;
  background-size: 430px auto, auto;
  border-top-left-radius: ${({ theme }) => theme.space[2]};
  border-top-right-radius: ${({ theme }) => theme.space[2]};

  ${hiDPI(2)} {
    background-image: url(${pattern1_1236}),
      linear-gradient(
        to right,
        ${(props) => props.theme.colors.fantasy},
        ${(props) => props.theme.colors.blueLight}
      );
  }

  ${(props) =>
    props.variant === "light" &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      background-color: transparent;
    `};
`;

const DialogHeading = styled.h2`
  padding: ${({ theme }) => theme.space[5]};
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  text-align: left;
`;

interface IDialogHeaderProps {
  children: React.ReactNode;
  closeDialog: () => void;
}

interface IStyledHeaderProps {
  variant?: "light" | undefined;
}

type DialogHeaderProps = IDialogHeaderProps & IStyledHeaderProps;

const DialogHeader: React.FC<DialogHeaderProps> = ({
  children,
  variant,
  closeDialog,
}) => (
  <StyledHeader variant={variant}>
    <DialogHeading id="ism-dialog-title">{children}</DialogHeading>
    <CloseButtonWrap>
      <Button $variant="tertiary" onClick={closeDialog}>
        <CloseLabel>close</CloseLabel>
        <CloseIcon title="Close" />
      </Button>
    </CloseButtonWrap>
  </StyledHeader>
);

export default DialogHeader;
