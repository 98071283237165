import _, { orderBy } from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProposedPicks, ISquadState } from "./types";
import { squadApi } from "../api/squad";
import { IError } from "../../app/types";
import { myTeamApi } from "../api/myTeam";
import { IElementsById } from "../elements";
import { ITransfer } from "../entries";
import { IPick } from "../myTeam";

const picksInSquadOrder = (picks: IPick[], elementsById: IElementsById) =>
  orderBy(picks, [
    (p) => elementsById[p.element].element_type,
    (p) => p.element,
  ]);

const initialState: ISquadState = {
  lastChange: {
    element: 0,
    type: "none",
  },
  proposed: {},
  saved: {},
  transferState: null,
  latestTransfers: [],
  error: null,
};

const squad = createSlice({
  name: "squad",
  initialState,
  reducers: {
    fetchLatestTransfers: (state, action: PayloadAction<ITransfer[]>) => ({
      ...state,
      latestTransfers: action.payload,
    }),
    proposeElementInPosition: {
      reducer: (
        state,
        action: PayloadAction<{ element: number; position: number }>
      ) => ({
        ...state,
        lastChange: {
          element: action.payload.element,
          type: "addition",
        },
        proposed: {
          ...state.proposed,
          [action.payload.position]: action.payload.element,
        },
      }),
      prepare: (element: number, position: number) => {
        return { payload: { element, position } };
      },
    },
    removeElementInPosition: {
      reducer: (
        state,
        action: PayloadAction<{ element: number; position: number }>
      ) => ({
        ...state,
        lastChange: {
          element: action.payload.element,
          type: "removal",
        },
        proposed: _.omitBy(
          { ...state.proposed },
          (value, key) =>
            key === action.payload.position.toString() &&
            value === action.payload.element
        ),
      }),
      prepare: (element: number, position: number) => {
        return { payload: { element, position } };
      },
    },
    resetLastChange: (state) => ({
      ...state,
      lastChange: initialState.lastChange,
    }),
    resetProposed: {
      reducer: (state, action: PayloadAction<IProposedPicks | {}>) => ({
        ...state,
        lastChange: initialState.lastChange,
        proposed: action.payload,
      }),
      prepare: (data?: IProposedPicks) => {
        return { payload: data ? data : {} };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action) => {
        state.proposed = picksInSquadOrder(
          action.payload.data.picks,
          action.payload.elementsById
        ).reduce<Record<string, number>>(
          (memo, p, i) => ({ ...memo, [i + 1]: p.element }),
          {}
        );
      }
    );
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action) => {
        state.saved = picksInSquadOrder(
          action.payload.data.picks,
          action.payload.elementsById
        ).reduce<Record<string, IPick>>(
          (memo, p, i) => ({ ...memo, [i + 1]: p }),
          {}
        );
      }
    );
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action) => {
        state.transferState = action.payload.data.transfers;
      }
    );
    builder.addMatcher(
      myTeamApi.endpoints.saveMyTeam.matchFulfilled,
      (state, action) => {
        state.proposed = picksInSquadOrder(
          action.payload.data.picks,
          action.payload.elementsById
        ).reduce<Record<string, number>>(
          (memo, p, i) => ({ ...memo, [i + 1]: p.element }),
          {}
        );
      }
    );
    builder.addMatcher(
      myTeamApi.endpoints.saveMyTeam.matchFulfilled,
      (state, action) => {
        state.saved = picksInSquadOrder(
          action.payload.data.picks,
          action.payload.elementsById
        ).reduce<Record<string, IPick>>(
          (memo, p, i) => ({ ...memo, [i + 1]: p }),
          {}
        );
      }
    );
    builder.addMatcher(
      squadApi.endpoints.createSquad.matchRejected,
      (state, action: PayloadAction<IError>) => {
        state.error = action.payload;
      }
    );
    builder.addMatcher(
      squadApi.endpoints.fetchLatestTransfers.matchFulfilled,
      (state, action: PayloadAction<ITransfer[]>) =>
        squad.caseReducers.fetchLatestTransfers(state, action)
    );
    builder.addMatcher(
      squadApi.endpoints.makeTransfers.matchRejected,
      (state, action: PayloadAction<IError>) => {
        state.error = action.payload;
      }
    );
  },
});

export const {
  fetchLatestTransfers,
  proposeElementInPosition,
  removeElementInPosition,
  resetLastChange,
  resetProposed,
} = squad.actions;

export const squadSlice = squad;
export default squad.reducer;
