import styled, { css } from "styled-components";
import { StyledButtonLink } from "../ButtonLink";
import { LinkStyles } from "../Link/Link";

const SharedListStyles = css`
  line-height: 1.6;

  &:last-child {
    padding-bottom: 0;
  }
`;

const Copy = styled.div`
  h4,
  h5,
  p,
  ul,
  ol,
  table {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  h6 {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }

  ul,
  ol {
    padding-left: ${({ theme }) => theme.space[6]};
  }

  ul li {
    list-style-type: disc;
    ${SharedListStyles}
  }

  ol li {
    list-style-type: inherit;
    ${SharedListStyles}
  }

  a {
    ${LinkStyles}
  }

  /* Turn off underline on ButtonLinks */
  ${StyledButtonLink}:hover::after {
    height: 0;
  }
`;

export default Copy;
