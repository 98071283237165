import { rgba } from "polished";
import styled from "styled-components";

export const StyledStatItem = styled.li`
  flex: 1;
  min-height: 4.6rem;
  padding: 0 ${(props) => props.theme.space[1]};
  font-size: 1rem;
  text-align: center;
  list-style-type: none;

  &:not(:first-child) {
    position: relative;

    &::before {
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 0;
      width: 1px;
      content: "";
      background-color: ${rgba("black", 0.1)};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex-basis: 12.5%;
  }
`;

export const StatHeading = styled.h3`
  margin-bottom: 0.2rem;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: inherit;
`;

export const StatValue = styled.div`
  margin-bottom: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: 1;
`;

export const StatRank = styled.div`
  font-size: 1rem;
`;
