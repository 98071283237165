import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const LinkStyles = css`
  position: relative;
  z-index: 1;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: none;

    ::after {
      position: absolute;
      right: 0;
      bottom: 1px;
      left: 0;
      z-index: -1;
      height: 1px;
      content: "";
      background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.purpleLight},
        ${({ theme }) => theme.colors.blueLight}
      );
    }
  }
`;

const Link = styled(RouterLink)`
  ${LinkStyles}
`;

export default Link;
