import { ellipsis, hideVisually, rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components";

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  border: 0;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const TextGradient = css`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.fantasy},
    ${({ theme }) => theme.colors.blueLight}
  );

  /* 
  Stylelint is disabled on these prefixes because the property-no-vendor-prefix
  rule doesn't allow them, however we need them for setting the gradients
  as background images clipped on text.
  See Step 3 in https://fossheim.io/writing/posts/css-text-gradient/.
  */
  /* stylelint-disable */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  /* stylelint-enable */

  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export const GradientBorder = css`
  position: relative;

  ::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 1px;
    content: "";
    background-image: linear-gradient(
      to right,
      ${rgba("white", 0)} 0%,
      ${rgba("white", 0.5)} 50%,
      ${rgba("white", 0)} 100%
    );
  }
`;

export const MatchValueBase = css`
  padding: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 1;
  text-align: center;
  border-radius: 2px;
`;

export const NewTabCopy: React.FC = () => (
  <VisuallyHidden> Opens in new tab</VisuallyHidden>
);
