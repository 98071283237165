import debounce from "lodash/debounce";
import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { NextIcon, PrevIcon } from "../Icons/Chevrons";

const TableHeader = styled.header`
  max-width: 1180px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const TableTitleRow = styled.div`
  margin: 30px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const TableNavRow = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1180px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-left: 240px;
  }
`;

const TableNavButtonWrap = styled.div`
  width: 48%;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 25%;
  }
`;

const KeyWrapper = styled.div`
  display: flex;
`;

const KeyHeading = styled.h3`
  margin-top: 1rem;
  margin-right: 0.4rem;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1.2rem;
`;

interface IKeyItem {
  difficulty: string;
}

const KeyItem = styled.span<IKeyItem>`
  display: inline-block;
  padding: 10px;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].color};
  background-color: ${({ theme, difficulty }) =>
    theme.colors.difficulties[difficulty].bg};
`;

type Props = {
  title: string;
  showPrevBtn: boolean;
  showNextBtn: boolean;
  onHandlePrevClick: () => void;
  onHandleNextClick: () => void;
};

const FDRHeader: React.FC<Props> = ({
  title,
  showPrevBtn,
  showNextBtn,
  onHandlePrevClick,
  onHandleNextClick,
}) => {
  return (
    <TableHeader>
      <TableTitleRow>
        <div className="mb-4">
          <h2 data-testid="title">{title}</h2>
        </div>
        <KeyWrapper data-testid="key">
          <div>
            <KeyHeading>FDR Key: </KeyHeading>
          </div>
          <div>
            <KeyItem difficulty="easy">Easy</KeyItem>
            <KeyItem difficulty="medium">Medium</KeyItem>
            <KeyItem difficulty="hard">Hard</KeyItem>
          </div>
        </KeyWrapper>
      </TableTitleRow>
      <TableNavRow>
        <TableNavButtonWrap>
          {showPrevBtn && (
            <Button
              data-testid="prev-btn"
              onClick={debounce(onHandlePrevClick, 250)}
              $variant="tertiary"
              $isFullWidth={true}
            >
              <PrevIcon />
              Previous GW
            </Button>
          )}
        </TableNavButtonWrap>
        <TableNavButtonWrap>
          {showNextBtn && (
            <Button
              data-testid="next-btn"
              onClick={debounce(onHandleNextClick, 250)}
              $variant="tertiary"
              $isFullWidth={true}
            >
              Next GW
              <NextIcon />
            </Button>
          )}
        </TableNavButtonWrap>
      </TableNavRow>
    </TableHeader>
  );
};

export default FDRHeader;
