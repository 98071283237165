import styled from "styled-components";

export const StyledSubHeading = styled.h3`
  align-self: center;
  margin: ${(props) => props.theme.space[2]} 0;
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: ${(props) => props.theme.fontSizes[3]};
  line-height: 1;
`;

export const StyledBench = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  width: 90%;

  /* TODO remove margin bottom when we create layout in https://github.com/ismfg/pl-issues/issues/270 */
  margin: ${(props) => props.theme.space[5]} auto
    ${(props) => props.theme.space[0]};
  background-color: rgb(255 255 255 / 1);
  border: 1px solid ${({ theme }) => theme.colors.purple10};
  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  border-top-right-radius: ${({ theme }) => theme.radii[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 60%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    width: 70%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[6]}) {
    width: 60%;
  }
`;
