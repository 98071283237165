import styled from "styled-components";
import Title from "../../../components/Title";

export const ActionBar = styled.ul`
  display: flex;
  flex: 1 1 100%;
  gap: 5px;
  justify-content: space-between;
  order: 3;
  padding: 0;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
    justify-content: end;
    order: 2;
  }
`;

export const ActionBarItem = styled.li`
  flex-basis: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-basis: auto;
  }
`;

export const MyLeaguesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  order: 1;
  margin-bottom: ${({ theme }) => theme.space[5]};
`;

export const TagLine = styled.p`
  flex: 1 1 100%;
  order: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    order: 3;
  }
`;

export const StyledTitle = styled(Title)`
  flex: 1;
  align-self: center;
  padding: 0;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 2;
  }
`;

export const LeaguePanelWrap = styled.div`
  margin: ${({ theme }) => `${theme.space[1]}  0 ${theme.space[5]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;
