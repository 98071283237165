import moment from "moment";
import { size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components";
import BoldHyperlink from "../../src/components/BoldHyperlink";
import {
  GridItem,
  GridWrapper,
  SidebarGrid,
} from "../../src/components/Layout";
import NewsLeader from "../../src/components/NewsLeader";
import ScoutCard, {
  ScoutBody,
  ScoutItem,
  ScoutList,
  ScoutPattern,
  ScoutTitle,
} from "../../src/components/ScoutCard";
import ScoutErrorBoundary from "../../src/components/ScoutErrorBoundary";
import ScoutNav from "../../src/components/ScoutNav";
import SubHeading from "../../src/components/SubHeading";
import Title from "../../src/components/Title";
import { NewTabCopy } from "../../src/components/Utils";
import HelmetHead from "../components/HelmetHead";
import NewsThumb from "../components/NewsThumb/NewsThumb";
import { Figure, NewsLink, Thumb } from "../components/NewsThumb/styles";
import ScoutPanel from "../components/ScoutPanel/ScoutPanel";
import BaseADIcon from "../img/icons/audio-description.svg?react";
import BaseCCIcon from "../img/icons/closed-caption.svg?react";
import BasePlayIcon from "../img/icons/play.svg?react";
import partnerBadge44 from "../img/partner-badge-44.png";
import partnerBadge88 from "../img/partner-badge-88.png";
import FacebookLogo from "../img/social/facebook.svg?react";
import TwitterLogo from "../img/social/twitter.svg?react";
import { IPulseNewsArticle, IPulseResponse } from "../utils/pulse";
import { getNewsAndVideos } from "../utils/scout";

export const Widget = styled.section`
  position: relative;
  padding: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[5]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const WidgetHeader = styled.header`
  border-bottom: ${({ theme }) => theme.borders[0]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-bottom: none;
  }
`;

export const ScoutFooter = styled.div`
  padding-top: ${({ theme }) => theme.space[2]};
  text-align: right;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    position: absolute;
    top: 0.3rem;
    right: 0;
    border-top: none;
  }
`;

const VideoFigure = styled.figure`
  transition: all 0.1s;
`;

const Image = styled.div`
  position: relative;
  overflow: hidden;
`;

const ThumbPlayIcon = styled(BasePlayIcon)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size("auto", "12%")};
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: inline;
    min-width: 4rem;
    max-width: 6rem;
    opacity: 0.8;
    fill: white;
    transform: translate(-50%, -50%);
  }
`;

const RunTime = styled.span`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  line-height: 2.4rem;
  color: #fff;
  background: ${({ theme }) => theme.colors.fantasy};
`;

const PlayIconSmall = styled(BasePlayIcon)`
  ${size(10)}
  margin: 0 0.4rem;
  fill: #2f1351;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: 0 ${({ theme }) => theme.space[2]};
  }
`;

const PlayTime = styled.time`
  padding: 0 0.4rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 1.84;
  background: ${({ theme }) => theme.colors.primary};
`;

const MainVideoWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.4rem;
  }
`;

const MainVideoCaption = styled.figcaption`
  ${ScoutPattern}
  padding: 2rem 2rem 3rem;
  background-repeat: no-repeat;
`;

const MainVideoTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.8rem;
  line-height: 1.2;

  ${NewsLink}:hover & {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2.5rem;
  }
`;

const VideoText = styled.p`
  margin: 1rem 0;
  font-size: 1.2rem;
  line-height: 1.8;
  color: inherit;
  word-break: break-word;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.5rem;
  }
`;

interface IVideoIcon {
  color?: string;
}

const CCIcon = styled(BaseCCIcon)<IVideoIcon>`
  ${size(12, 18)};
  fill: #6c6c6c;

  ${(props) =>
    props.color === "primary" &&
    css`
      fill: ${(props) => props.theme.colors.primary};
    `}
`;

const ADIcon = styled(BaseADIcon)<IVideoIcon>`
  ${size(12, 18)};
  fill: #6c6c6c;

  ${(props) =>
    props.color === "primary" &&
    css`
      fill: ${(props) => props.theme.colors.primary};
    `}
`;

const ScoutVideo = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
  }
`;

const VideoSection = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 50%;
  }
`;

const VideoList = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.4rem;
  }
`;

const VideoItem = styled.li`
  margin-bottom: ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 1 0 50%;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
`;

const SocialBody = styled.div`
  padding: ${({ theme }) => theme.space[1]};
`;

const Logo = styled.img`
  margin: 5px;
`;

const SocialLinks = styled.ul`
  padding: ${({ theme }) => theme.space[1]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  background-color: white;
  border-radius: 3px;
`;

interface ISocialLinkStyleProps {
  $network: string;
}

const SocialLink = styled.a<ISocialLinkStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  color: ${(props) => props.theme.colors.social[props.$network].color};
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.social[props.$network].bg};
  border-radius: 2px;

  :hover {
    text-decoration: underline;
  }
`;

const SocialLinkItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;

const SocialLinkText = styled.span`
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 1.2rem;
`;

const TwitterWrap = styled.div`
  margin: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0;
  }
`;

interface ISocialIcons {
  color?: string;
  hasCC?: boolean;
  hasAD?: boolean;
}

const SocialIcons: React.FC<ISocialIcons> = ({ color, hasCC, hasAD }) => (
  <div className="flex">
    <div className="mr-[2px]">{hasCC && <CCIcon color={color} />}</div>
    <div>{hasAD && <ADIcon color={color} />}</div>
  </div>
);

type VideoProps = {
  video: any;
};

const Video: React.FunctionComponent<VideoProps> = ({ video }) => {
  return (
    <NewsLink href={`https://www.premierleague.com/video/single/${video.id}`}>
      <Figure>
        <Thumb>
          <ThumbPlayIcon />
          <NewsThumb
            news={video}
            imgSizes={{
              x1: { width: 214, height: 120 },
              x2: { width: 428, height: 240 },
            }}
          />
          <RunTime>
            <PlayIconSmall />
            <PlayTime dateTime={video.duration}>
              {moment(video.duration * 1000).format("mm:ss")}
            </PlayTime>
          </RunTime>
        </Thumb>
        <ScoutBody>
          <ScoutTitle>{video.title}</ScoutTitle>
          <SocialIcons hasCC={video.closedCaptioned} />
        </ScoutBody>
      </Figure>
    </NewsLink>
  );
};

interface IState {
  news: IPulseResponse | null;
  videos: IPulseResponse | null;
  externalNews: Record<string, IPulseNewsArticle> | null;
}

export const Scout = () => {
  const initialState: IState = { news: null, videos: null, externalNews: null };
  const [state, setState] = React.useState(initialState);

  const { news, videos, externalNews } = state;

  const mainVideo = videos ? videos.content[0] : null;

  React.useEffect(() => {
    const twttr = (window as any).twttr;
    if (twttr && twttr.widgets) {
      twttr.widgets.load();
    }
    // TODO - Should set an error state then can throw in render and use the
    // error boundary
    (async () => {
      setState(
        await getNewsAndVideos(
          7,
          5,
          "FPL%20Challenge%2C%20Fantasy%20Football%20Scout"
        )
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem>
          <Title>The Scout</Title>
        </GridItem>
        <GridItem>
          <div className="-mt-3">
            <ScoutNav />
          </div>
        </GridItem>

        <HelmetHead
          title="The Scout, Fantasy Football Tips &amp; Advice | Fantasy Premier League"
          description="To get tips and find out about the latest form and stats for Fantasy Premier League players, read what The Scout has to say, on the official website of the Premier League."
        />

        {news && videos && (
          <GridItem $colSpan={1}>
            {/* Promoted Content */}
            <div className="mb-[4rem]">
              <NewsLeader news={news.content[0]} />
            </div>
            {/* News */}
            <Widget>
              <div className="mb-3">
                <WidgetHeader>
                  <SubHeading>Latest FPL News</SubHeading>
                </WidgetHeader>
              </div>
              <ScoutList>
                {news.content.slice(1, 7).map((n) => (
                  <ScoutItem key={n.id} $width={33.33}>
                    <ScoutCard externalNews={externalNews} newsItem={n} />
                  </ScoutItem>
                ))}
              </ScoutList>
              <ScoutFooter>
                <BoldHyperlink href="https://www.premierleague.com/news?type=Fantasy">
                  More News
                </BoldHyperlink>
              </ScoutFooter>
            </Widget>

            {/* Videos */}
            <Widget>
              <div className="mb-3">
                <WidgetHeader>
                  <SubHeading>Latest FPL Videos</SubHeading>
                </WidgetHeader>
              </div>
              <ScoutVideo>
                <VideoSection>
                  <MainVideoWrap>
                    <NewsLink
                      href={`https://www.premierleague.com/video/single/${mainVideo.id}`}
                    >
                      <VideoFigure>
                        <Image>
                          <ThumbPlayIcon />
                          <NewsThumb
                            news={mainVideo}
                            imgSizes={{
                              x1: { width: 436, height: 245 },
                              x2: { width: 872, height: 490 },
                            }}
                          />
                          <RunTime>
                            <PlayIconSmall />
                            <PlayTime dateTime={mainVideo.duration}>
                              {moment(mainVideo.duration * 1000).format(
                                "mm:ss"
                              )}
                            </PlayTime>
                          </RunTime>
                        </Image>
                        <MainVideoCaption>
                          <MainVideoTitle>{mainVideo.title}</MainVideoTitle>
                          <VideoText>{mainVideo.description}</VideoText>
                          <SocialIcons
                            hasCC={mainVideo.closedCaptioned}
                            color="primary"
                          />
                        </MainVideoCaption>
                      </VideoFigure>
                    </NewsLink>
                  </MainVideoWrap>
                </VideoSection>
                <VideoSection>
                  <VideoList>
                    {videos.content.slice(1, 5).map((v) => (
                      <VideoItem key={v.id}>
                        <Video video={v} />
                      </VideoItem>
                    ))}
                  </VideoList>
                </VideoSection>
              </ScoutVideo>
              <ScoutFooter>
                <BoldHyperlink href="https://www.premierleague.com/video/latest?term=FPL">
                  More FPL Video
                </BoldHyperlink>
              </ScoutFooter>
            </Widget>
          </GridItem>
        )}

        <ScoutPanel
          title="FPL on Social"
          icon={
            <Logo
              srcSet={`${partnerBadge44}, ${partnerBadge88} 2x`}
              src={partnerBadge44}
              alt=""
            />
          }
        >
          <SocialBody>
            <SocialLinks>
              <SocialLinkItem>
                <SocialLink
                  href="https://www.facebook.com/OfficialFPL"
                  $network="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookLogo />
                  <SocialLinkText>FPL on Facebook</SocialLinkText>
                  <NewTabCopy />
                </SocialLink>
              </SocialLinkItem>
              <SocialLinkItem>
                <SocialLink
                  href="https://twitter.com/officialfpl"
                  $network="x"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterLogo />
                  <SocialLinkText>FPL on X</SocialLinkText>
                  <NewTabCopy />
                </SocialLink>
              </SocialLinkItem>
            </SocialLinks>
            <TwitterWrap>
              <a
                className="twitter-timeline"
                data-height="1200"
                data-link-color="black"
                href="https://twitter.com/OfficialFPL?ref_src=twsrc%5Etfw"
              >
                Tweets by OfficialFPL
              </a>
            </TwitterWrap>
          </SocialBody>
        </ScoutPanel>
      </SidebarGrid>
    </GridWrapper>
  );
};

const ScoutWrapper: React.FC = () => (
  <ScoutErrorBoundary>
    <Scout />
  </ScoutErrorBoundary>
);

export default ScoutWrapper;
