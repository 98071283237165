import * as React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { useAppDispatch } from "../../rtk-core/src/app/hooks";
import {
  getElementsById,
  showElementSummary,
} from "../../rtk-core/src/features/elements";
import ElementStatus from "../ElementStatus";
import { VisuallyHidden } from "../Utils";

interface IStyledProps {
  $variant?: "list" | "pitch";
}

const StyledElementDialogButton = styled.button<IStyledProps>`
  display: block;
  width: 100%;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
  border: 0;

  ${(props) =>
    props.$variant === "list" &&
    css`
      padding: 1.3rem ${props.theme.space[1]};
    `}
`;

interface IProps {
  elementId: number;
  variant?: "list" | "pitch";
}

const ElementDialogButton: React.FC<IProps> = ({
  elementId,
  variant = "pitch",
}) => {
  const dispatch = useAppDispatch();

  const showElementDialog = (elementId: number) => {
    dispatch(showElementSummary(elementId));
  };

  const elementsById = useSelector(getElementsById);
  return (
    <StyledElementDialogButton
      onClick={() => showElementDialog(elementId)}
      $variant={variant}
    >
      <ElementStatus element={elementsById[elementId]} />
      <VisuallyHidden>View player information</VisuallyHidden>
    </StyledElementDialogButton>
  );
};

export default ElementDialogButton;
