import styled from "styled-components";

const TextDivider = styled.div`
  margin: ${(props) => props.theme.space[6]} 0;
  overflow: hidden;
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.purple60};
  text-align: center;

  &::before,
  &::after {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    vertical-align: middle;
    content: "";
    background-color: ${(props) => props.theme.colors.purple30};
  }

  &::before {
    right: ${(props) => props.theme.space[4]};
    margin-left: -50%;
  }

  &::after {
    left: ${(props) => props.theme.space[4]};
    margin-right: -50%;
  }
`;

export default TextDivider;
