export interface IChallengeCopy {
  description: string;
  detailDescription: string;
  detailTitle: string;
  filterDescription: string;
  filterLabel: string;
  title: string;
}

export interface EventAssets {
  copy: IChallengeCopy;
  lockupPath: string;
  featuredElementStatSort: string;
}

export const getChallengeAssets = (eventId: number) => {
  const challengeAssets: Record<number, EventAssets> = {
    1: {
      copy: {
        description: "Unlimited Budget, 5-a-side.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Debut",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    2: {
      copy: {
        description:
          "Goals are worth double points. Your squad may only contain 1 Goalkeeper and 4 Forwards.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "All Out Attack",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    3: {
      copy: {
        description:
          "Only players from the promoted teams, Ipswich, Southampton and Leicester City are available.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Promoted Punt",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    4: {
      copy: {
        description:
          "Clean sheets are worth double points. Your squad may only contain 2 Goalkeepers and 3 Defenders.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Too Big at the Back",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    5: {
      copy: {
        description: "Only players from the Americas",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Americas",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    6: {
      copy: {
        description: "Only players from Africa",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Africa",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    7: {
      copy: {
        description: "Double points for players from Asia-Pacific",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "Show Players from Asia-Pacific Only",
        title: "Asia-Pacific",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    8: {
      copy: {
        description:
          "Interceptions and tackles are worth 1 point. 3 recoveries are worth 1 point.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Ball Winners",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "interceptions",
    },
    9: {
      copy: {
        description: "Key passes and crosses worth 2 points.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Playmakers",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "key_passes",
    },
    10: {
      copy: {
        description:
          "Fouls won are worth 2 points. Fouls conceded are worth -1 point.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Foul Play",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "fouls_won",
    },
    11: {
      copy: {
        description: "Shots on target are worth 3 points",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Accuracy",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "shots_on_target",
    },
    12: {
      copy: {
        description: "The budget is set to only £45m.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Economise",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    13: {
      copy: {
        description: "Liverpool and Man City players earn double points.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "Show Players from Liverpool and Man City",
        title: "Haaland or Salah?",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    14: {
      copy: {
        description: "Man United players earn double points.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "Show Players from Man United",
        title: "Red Hot Deal",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    15: {
      copy: {
        description: "Players costing over £9m earn double points.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "Show players costing over £9m at the start of GW14",
        title: "Big Spender",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    16: {
      copy: {
        description: "Players costing under £6m earn double points.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "Show players costing under £6m at the start of GW15",
        title: "Bargains",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    17: {
      copy: {
        description: "Players at home earn double points",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "Double points for home teams",
        filterLabel: "Show Players playing at home in GW17",
        title: "Home for the Holidays",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    18: {
      copy: {
        description: "Goals 'outside the box' are worth 7 extra points",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Out of the Box",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    19: {
      copy: {
        description: "The formation must be 2-0-2-5",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Final Countdown",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    20: {
      copy: {
        description: "Big chances created are worth 5 additional points",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "New Year, New Chance",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
  };
  // check challengeAssets has a key for the event otherwise return null
  return Object.hasOwn(challengeAssets, eventId)
    ? challengeAssets[eventId]
    : null;
};
