import * as React from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
// Some webpack reason can't do import "tippy.js/dist/tippy.css";
// import "@tippyjs/react/node_modules/tippy.js/dist/tippy.css";
import "tippy.js/dist/tippy.css";

const StyledTooltip = styled(Tippy)`
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0 / 0.25);

  .tippy-backdrop {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0 / 0.25);
  }

  .tippy-content {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.black};
  }

  /* Styling the arrow for different placements */
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: white;
  }

  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: white;
  }
`;

export const TooltipLabel = styled.span`
  text-decoration: underline dotted;
`;

interface IProps {
  content: React.ReactNode | React.ReactNode[];
  children: React.ReactElement<any>;
}

const Tooltip: React.FC<IProps> = ({ children, content }) => {
  // We wrap in a span to stop issues with google translate
  return (
    <StyledTooltip
      content={<span>{content}</span>}
      arrow={true}
      placement="top"
    >
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
